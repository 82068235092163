import Cookies from 'js-cookie'
const getuser = Cookies.get('user');
// console.log("getuser", JSON.parse(getuser))

const initialState = {
    user: {},
}
if (getuser !== undefined) {
    initialState.user = JSON.parse(getuser)
}
const LoginReducer = (state = initialState, action) => {
    switch (action.type) {

        case "AUTH/LOGIN/SET":
            Cookies.set('user', JSON.stringify(action.payload));
            return { ...state, user: action.payload }

        case "AUTH/FORGOT/SEND":
            return { ...state, forgotEmailStatus: true }

        case "Set/Admin/EmailID":
            return { ...state, email: action.payload }

        case "Set/Forgot/Otp":
            return { ...state, otpSend: true }

        case "Set/Otp/Submit":
            return { ...state, otpVarified: true, otpCode: action.payload }

        case "Password/Changed":
            return { ...state, otpVarified: true, passwordChangedStatus: true }

        case "LOGOUT":
            Cookies.remove('user');
            return { user: {} }

        case "LOADING/SET":
            return { ...state, LOADER: action.payload }

        default: return state

    }
}

export default LoginReducer;
