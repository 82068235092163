import DashboadReducer from '../pages/Dashboard/reducer';
import LoginReducer from '../pages/Auth/reducer';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form'
import subReducer from '../pages/Subscriptions/reducer'
import historyReducer from '../pages/History/reducer'
import usersReducer from '../pages/Users/reducer'

const rootReducer = combineReducers({
    Dashboad: DashboadReducer,
    Login: LoginReducer,
    Subscription: subReducer,
    History: historyReducer,
    user: usersReducer,
    form: formReducer
});

export default rootReducer;