const usersReducer = (state = {}, action) => {
    switch (action.type) {
        case "USERS/LIST/SET":
            return { ...state, userListData: action.payload }

        case "users/pdfExport/SET":
            return { ...state, usersPdfExportData: action.payload }

        case "acsess/change/set":
            return { ...state, acsesschange: state.acsesschange ? state.acsesschange : 1 }

        case "check/box/date/set":
            return { ...state, checkData: action.payload }

        case "bulk/Password/Reset/set":
            return { ...state, bulkPasswordResetStatus: state.bulkPasswordResetStatus ? state.bulkPasswordResetStatus + 1 : 1 }

        case "get/User/Data/set":
            return { ...state, userDataFields: action.payload }

        case "get/Users/List/set":
            return { ...state, userEmailList: action.payload }

        case "filtered/Data":
            return { ...state, userEmailList: { data: action.payload } }

        case "send/mail/set":
            return { ...state, mailStatus: state.mailStatus ? state.mailStatus + 1 : 1 }

        case "selected/Accsess":
            return { ...state, selectedAccsess: action.payload }

        default: return state

    }
}

export default usersReducer;