import "./App.scss";
import { Route, Routes } from 'react-router-dom';
import Sidebar from "./pages/Sidebar/Sidebar";
import UserProfile from "./pages/UserProfile/UserProfile";
import User from "./pages/Users/User";
import Dashboard from "./pages/Dashboard/Dashboard";
import Subscription from "./pages/Subscriptions/Subscription";
import TransactionHistory from "./pages/History/History";
import TransactionDetailsHistory from "./pages/History/HistoryDetailsTable";
import Emails from "./pages/Emails/Email";
import Login from "./pages/Auth/login";
import Forgot from "./pages/Auth/Forgot";
import Reset from "./pages/Auth/Reset";
import {
  matchPath,
  useLocation
} from "react-router-dom";
import IO from 'socket.io-client'
import { useEffect } from "react";
import LoadingOverlay from 'react-loading-overlay';
import { connect } from 'react-redux';


function App(props) {
  const { pathname } = useLocation();

  // useEffect(() => {
  //   let socket = IO("http://localhost:3444", {
  //     "autoConnect": false,
  //     "path": "/snap-io",
  //     "query": {
  //       "jwt": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InNhbWVlckBnZXRuYWRhLmNvbSIsInVzZXJfaWQiOiJhYWVhOWE1Ny1mMTIzLTRjMWYtYTFlNy0zMDg2ODJhNmE3Y2MiLCJpYXQiOjE2NTI0MzkwMTR9.SW1EO6TfgijsTh5i3rEgqS_3OLOOvWcEa1HWmBhRJ6w"
  //     },
  //     "secure": true,
  //     "transports": ["websocket"]
  //   });

  //   socket.on("connect", () => {
  //     console.log("LLLLLLLLLLLLLLLL")
  //   });
  //   socket.connect("http://localhost:3444", () => {
  //     console.log("LLLLLLLLLLLLLLLL")
  //   })


  //   socket.on("aaaaaaaa", () => {
  //     console.log("LLLLLLLLLLLLLLLLaaaaaaaaa")
  //   });

  //   setTimeout(() => {
  //     console.log("LLLLLLLLLLLLLLLLpppppppppppppppppp", socket.connected)

  //     socket.emit("topic", "data");

  //   }, 5000)
  // }, [])

  useEffect(() => {
    const loaderHeight = document.getElementsByClassName("_loading_overlay_overlay");
    if (loaderHeight[0]) {
      console.log("loaderHeight", loaderHeight[0].style.height = `${screen.height+150}px`)
    }
  })
  // loaderHeight[0].style.height = screen.height+"px"


  return (
    <>
      <LoadingOverlay
        active={props.LOADER}
        spinner
        text='Loading your content...'
      >
        <p>Some content or children or something.</p>
      </LoadingOverlay>
      <div className="App">
        {!(pathname == "/" || pathname == "/forgotPassword" || pathname == "/resetPassword") && <Sidebar />}
        <div className={window.location.pathname != "/" && "main-content-wrapper"}>
          <Routes>
            <Route exact path="/" element={<Login />}></Route>
            <Route exact path="/forgotPassword" element={<Forgot />}></Route>
            <Route exact path="/resetPassword" element={<Reset />}></Route>
            <Route exact path="/dashboard" element={<Dashboard />}></Route>
            <Route path="/users" element={<User />}></Route>
            <Route path="/users/user-profile" element={<UserProfile />}></Route>
            <Route path="/subscriptions" element={<Subscription />}></Route>
            <Route
              path="/transaction-history"
              element={<TransactionHistory />}
            ></Route>
            <Route
              path="/transaction-details"
              element={<TransactionDetailsHistory />}
            ></Route>
            <Route path="/emails" element={<Emails />}></Route>
          </Routes>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    LOADER: state.Login.LOADER
  }
}

export default connect(mapStateToProps)(App);
