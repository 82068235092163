
import React, { useEffect } from "react";
import "../Login.scss";
import { connect } from 'react-redux';
import { getAdminEmailID, FrogotSubmit, OtpSubmit } from './../action'
import { inputField } from "../../../components/Input/fields";
import { Field, reduxForm } from 'redux-form'
import { useNavigate } from "react-router";
import { isNumber, isEmpty, maxLenght } from '../../../Helper/validation'

const validate = (values) => {
  const errors = {}

  if (isEmpty(values.code)) {
    errors.code = 'Enter your OTP.';
  }
  if (!isNumber(values.code)) {
    errors.code = 'OTP Must be Number.';
  }
  if (maxLenght(values.code, 4)) {
    errors.code = 'OTP Should Not Greater Than 4 Characters.';
  }
  return errors

}

function Forgotscreen(props) {
  let navigation = useNavigate()

  useEffect(() => {
    props.getAdminEmailID()
  }, [])


  useEffect(() => {
    props.otpVarified && navigation("/resetPassword")
  }, [props.otpVarified])

  let submit = (value) => {
    if (props.otpSend) {
      props.OtpSubmit(value)
    } else {
      props.FrogotSubmit(value)
    }
  }

  return (
    <div className="login-form d-flex flex-column">
      <h3>Forget password?</h3>
      {props.otpSend &&
        <h5>A verification code has been sent to your email. Please enter it below.</h5>
      }
      <form onSubmit={props.handleSubmit((values) => { submit(values) })} className="d-flex flex-column">
        {props.otpSend ?
          <Field name="code" component={inputField} type="text" styleClass="form-control user-id" placeholder="OTP" />
          :
          <Field name="email" disabled={true} component={inputField} type="text" styleClass="form-control user-id" />
        }
        <button type="submit" className="login-btn">Submit</button>
      </form>
    </div>
  );
}

Forgotscreen = reduxForm({
  form: '_Forgot',
  validate,
  destroyOnUnmount: false,
  enableReinitialize: true
})(Forgotscreen);

const mapStateToProps = (state, ownProps) => {
  console.log("state>>>>>>>>>>>", state)
  return {
    initialValues: {
      email: state.Login.email
    },
    email: state.Login.email,
    otpSend: state.Login.otpSend,
    otpVarified: state.Login.otpVarified,
  }
}
const mapDispatchToProps = dispatch => {

  return ({
    getAdminEmailID: (value) => { dispatch(getAdminEmailID(value)); },
    FrogotSubmit: (value) => { dispatch(FrogotSubmit(value)); },
    OtpSubmit: (value) => { dispatch(OtpSubmit(value)); },
  })
};

export default connect(mapStateToProps, mapDispatchToProps)(Forgotscreen);
