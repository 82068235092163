import React from "react";
import { NavLink } from "react-router-dom";
import "./Sidebar.scss";
import Logo from "../../assets/logo.png";
import SmallLogo from "../../assets/small-logo.png";
import Profile from "../../assets/profile.png";
import { connect } from 'react-redux';
import Input from "../../components/Input/Input";
import { FiChevronRight } from "react-icons/fi";
import { Logout } from './../Auth/action'

function Sidebar(props) {

  return (
    <>
      <nav className="navbar py-2">
        <div className="container-fluid">
          <div className="d-flex align-items-center">
            <a className="navbar-brand" type="button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="16"
                viewBox="0 0 18 16"
              >
                <path
                  id="Path_20"
                  data-name="Path 20"
                  d="M3,4H21V6H3Zm0,7H15v2H3Zm0,7H21v2H3Z"
                  transform="translate(-3 -4)"
                  fill="#0f3059"
                />
              </svg>
            </a>
            <ul className="navbar-nav me-auto">
              {/* <li className="nav-item">
                <form className="d-flex">
                  <Input
                    type="text"
                    styleClass="form-control me-2"
                    value="Search for anything..."
                  />
                  <svg
                    id="search-2-line"
                    xmlns="http://www.w3.org/2000/svg"
                    width="17.19"
                    height="17.19"
                    viewBox="0 0 17.19 17.19"
                  >
                    <path
                      id="Path_15"
                      data-name="Path 15"
                      d="M0,0H17.19V17.19H0Z"
                      fill="none"
                    />
                    <path
                      id="Path_16"
                      data-name="Path 16"
                      d="M8.446,2A6.446,6.446,0,1,1,2,8.446,6.449,6.449,0,0,1,8.446,2Zm0,11.46A5.014,5.014,0,1,0,3.433,8.446,5.013,5.013,0,0,0,8.446,13.46Zm6.077.051,2.026,2.026L15.537,16.55l-2.026-2.026,1.013-1.013Z"
                      transform="translate(-0.567 -0.567)"
                      fill="#858dac"
                    />
                  </svg>
                </form>
              </li> */}
            </ul>
          </div>
          <div className="d-flex profile-wrapper">
            {/* <div className="position-relative notifications">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="21.5"
                viewBox="0 0 20 21.5"
              >
                <path
                  id="Path_22"
                  data-name="Path 22"
                  d="M22,20H2V18H3V11.031a9,9,0,1,1,18,0V18h1ZM5,18H19V11.031a7,7,0,1,0-14,0Zm4.5,3h5a2.5,2.5,0,1,1-5,0Z"
                  transform="translate(-2 -2)"
                  fill="#858dac"
                />
              </svg>
              <span>0</span>
            </div> */}

            <div className="user-profile">
              <figure>
                <img src={Profile} alt="profile" />
              </figure>
              <a className="dropdown-toggle" data-bs-toggle="dropdown" href="#">
                Admin
              </a>
              <ul className="dropdown-menu">
                <li onClick={() => {
                  props.Logout()
                }} className="nav-item">
                  <NavLink className="nav-link log-out" to="/">
                    <svg xmlns="http://www.w3.org/2000/svg"
                      width="20" height="20" viewBox="20 20 470 450" >
                      <path d="M318.713,73.106c-32.723,0-64.571,9.208-92.103,26.628c-26.772,16.94-48.365,40.868-62.445,69.196l26.865,13.353
		c24.272-48.838,73.198-79.176,127.683-79.176c78.575,0,142.5,63.925,142.5,142.5s-63.925,142.5-142.5,142.5
		c-54.484,0-103.41-30.338-127.683-79.176l-26.865,13.353c14.08,28.328,35.673,52.256,62.445,69.196
		c27.531,17.42,59.38,26.628,92.103,26.628c95.117,0,172.5-77.383,172.5-172.5S413.83,73.106,318.713,73.106z"
                      />
                      <polygon points="318.713,260.606 318.713,230.606 57.426,230.606 91.819,196.213 70.606,175 0,245.607 70.606,316.213 91.819,295 
		57.425,260.606 	"/>
                    </svg>
                    <span className="tab-text"> Log out</span>
                  </NavLink>
                </li>
                {/* <li>
                  <a className="dropdown-item" href="#">
                    User 2
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    User 3
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <div className="sidebar">
        <NavLink to="/" href="" className="logo">
          <figure>
            <img src={Logo} alt="logo" className="py-3 m-auto big" />
            <img src={SmallLogo} alt="logo" className="py-3 m-auto small" />
          </figure>
        </NavLink>
        <div className="sidebar-wrapper">
          <ul className="nav">
            <li className="nav-item">
              <NavLink className="nav-link" to="/dashboard">
                <svg
                  id="dashboard-line"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    id="Path_1"
                    data-name="Path 1"
                    d="M0,0H24V24H0Z"
                    fill="none"
                  />
                  <path
                    id="Path_2"
                    data-name="Path 2"
                    d="M13,21V11h8V21ZM3,13V3h8V13Zm6-2V5H5v6ZM3,21V15h8v6Zm2-2H9V17H5Zm10,0h4V13H15ZM13,3h8V9H13Zm2,2V7h4V5Z"
                  />
                </svg>
                <span className="tab-text">Dashboard</span>
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <div className="dropdown">
                <NavLink
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                  to="/users"
                >
                  <svg
                    id="user-line"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      id="Path_3"
                      data-name="Path 3"
                      d="M0,0H24V24H0Z"
                      fill="none"
                    />
                    <path
                      id="Path_4"
                      data-name="Path 4"
                      d="M4,22a8,8,0,1,1,16,0H18A6,6,0,1,0,6,22Zm8-9a6,6,0,1,1,6-6A6,6,0,0,1,12,13Zm0-2A4,4,0,1,0,8,7,4,4,0,0,0,12,11Z"
                    />
                  </svg>
                  <span className="tab-text">Users</span>
                </NavLink>
                <ul className="dropdown-menu">
                  <li>
                    <NavLink className="dropdown-item" to="/users">
                      Users
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/users/user-profile">
                      User Profile
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li> */}
            {/* <li className="nav-item">
              <button className="dropdown-btn">
                <svg
                  id="user-line"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    id="Path_3"
                    data-name="Path 3"
                    d="M0,0H24V24H0Z"
                    fill="none"
                  />
                  <path
                    id="Path_4"
                    data-name="Path 4"
                    d="M4,22a8,8,0,1,1,16,0H18A6,6,0,1,0,6,22Zm8-9a6,6,0,1,1,6-6A6,6,0,0,1,12,13Zm0-2A4,4,0,1,0,8,7,4,4,0,0,0,12,11Z"
                  />
                </svg>
                <span className="tab-text">Dropdown</span>
              </button>
              <div className="dropdown-container">
                <NavLink to="/users">
                  <span className="tab-text"> Users</span>
                </NavLink>

                <NavLink to="/users/user-profile">
                  <span className="tab-text">User Profile</span>
                </NavLink>
              </div>
            </li> */}
            {/* <li className="nav-item drp-toggle">
              <div className="panel">
                <a
                  href="#"
                  className="nav-link"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseContent"
                  aria-expanded="false"
                  aria-controls="collapseContent"
                >
                  <div>
                    <svg
                      id="user-line"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        id="Path_3"
                        data-name="Path 3"
                        d="M0,0H24V24H0Z"
                        fill="none"
                      />
                      <path
                        id="Path_4"
                        data-name="Path 4"
                        d="M4,22a8,8,0,1,1,16,0H18A6,6,0,1,0,6,22Zm8-9a6,6,0,1,1,6-6A6,6,0,0,1,12,13Zm0-2A4,4,0,1,0,8,7,4,4,0,0,0,12,11Z"
                      />
                    </svg>
                    <span className="tab-text">Users</span>
                  </div>
                  <FiChevronRight className="icon" />
                </a>
                <div id="collapseContent" className="panel-collapse collapse">
                  <ul className="list-group">
                    <li className="list-group-item">
                      <NavLink to="/users">
                        <span className="tab-text"> Users</span>
                      </NavLink>
                    </li>
                    <li className="list-group-item">
                      <NavLink to="/users/user-profile">
                        <span className="tab-text">User Profile</span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </li> */}

            <li className="nav-item">
              {/* <li className="list-group-item"> */}
              <NavLink className="nav-link" to="/users">
                <svg
                  id="user-line"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    id="Path_3"
                    data-name="Path 3"
                    d="M0,0H24V24H0Z"
                    fill="none"
                  />
                  <path
                    id="Path_4"
                    data-name="Path 4"
                    d="M4,22a8,8,0,1,1,16,0H18A6,6,0,1,0,6,22Zm8-9a6,6,0,1,1,6-6A6,6,0,0,1,12,13Zm0-2A4,4,0,1,0,8,7,4,4,0,0,0,12,11Z"
                  />
                </svg>
                <span className="tab-text"> Users</span>
              </NavLink>
              {/* </li> */}
            </li>

            <li className="nav-item">
              <NavLink className="nav-link" to="/subscriptions">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="18"
                  viewBox="0 0 20 18"
                >
                  <path
                    id="Path_6"
                    data-name="Path 6"
                    d="M3,3H21a1,1,0,0,1,1,1V20a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V4A1,1,0,0,1,3,3Zm17,9H4v7H20Zm0-4V5H4V8Z"
                    transform="translate(-2 -3)"
                  />
                </svg>
                <span className="tab-text">Subscriptions</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/transaction-history">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                >
                  <path
                    id="Path_8"
                    data-name="Path 8"
                    d="M12,2A10,10,0,1,1,2,12H4A8,8,0,1,0,5.385,7.5H8v2H2v-6H4V6A9.981,9.981,0,0,1,12,2Zm1,5v4.585l3.243,3.243-1.415,1.415L11,12.413V7Z"
                    transform="translate(-2 -2)"
                  />
                </svg>
                <span className="tab-text">Transfer History</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/emails">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="18"
                  viewBox="0 0 20 18"
                >
                  <path
                    id="Path_10"
                    data-name="Path 10"
                    d="M3,3H21a1,1,0,0,1,1,1V20a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V4A1,1,0,0,1,3,3ZM20,7.238l-7.928,7.1L4,7.216V19H20ZM4.511,5l7.55,6.662L19.5,5Z"
                    transform="translate(-2 -3)"
                  />
                </svg>
                <span className="tab-text">Email</span>
                {/* <span className="badge">2</span> */}
              </NavLink>
            </li>
            {/* <li onClick={() => {
              props.Logout()
            }} className="nav-item">
              <NavLink className="nav-link" to="/">
                <svg xmlns="http://www.w3.org/2000/svg"
                  width="20" height="20" viewBox="20 20 470 450" >
                  <path d="M318.713,73.106c-32.723,0-64.571,9.208-92.103,26.628c-26.772,16.94-48.365,40.868-62.445,69.196l26.865,13.353
		c24.272-48.838,73.198-79.176,127.683-79.176c78.575,0,142.5,63.925,142.5,142.5s-63.925,142.5-142.5,142.5
		c-54.484,0-103.41-30.338-127.683-79.176l-26.865,13.353c14.08,28.328,35.673,52.256,62.445,69.196
		c27.531,17.42,59.38,26.628,92.103,26.628c95.117,0,172.5-77.383,172.5-172.5S413.83,73.106,318.713,73.106z"
                  />
                  <polygon points="318.713,260.606 318.713,230.606 57.426,230.606 91.819,196.213 70.606,175 0,245.607 70.606,316.213 91.819,295 
		57.425,260.606 	"/>
                </svg>
                <span className="tab-text">Log out</span>
              </NavLink>
            </li> */}
          </ul>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  console.log("state", state)
  return {
  }
}

const mapDispatchToProps = dispatch => {

  return ({
    Logout: (value) => { dispatch(Logout(value)); },
  })
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
