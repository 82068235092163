import { put, takeEvery, race, call, take } from 'redux-saga/effects'
import { ajaxRequest } from "../../Helper/apiCall"
import { toast } from 'react-toastify';


export function* LOGIN(action) {
  yield put({ type: 'LOADING/SET', payload: true })

  try {
    const response = yield race({
      success: call(ajaxRequest, {
        method: 'POST',
        url: `users/login`,
        data: action.payload,
      }),
      cancel: take('ROUTE/CHANGE')
    });

    if (response.success) {
      if (response.success.data.data.role === 1) {
        toast.success("Login Successful")
        yield put({ type: 'AUTH/LOGIN/SET', payload: response.success.data.data })
        yield put({ type: 'LOADING/SET', payload: false })
      } else {
        toast.error("Only Admin Can Login")
      }

    }

  } catch (error) {
    console.log("error",error)
    toast.error("Invalid Email or Password")
    yield put({ type: 'LOADING/SET', payload: false })
  }

}

export function* FORGOT(action) {
  yield put({ type: 'LOADING/SET', payload: true })

  try {
    const response = yield race({
      success: call(ajaxRequest, {
        method: 'POST',
        url: `users/forgotPassword`,
        data: action.payload,
      }),
      cancel: take('ROUTE/CHANGE')
    });

    if (response.success) {
      if (response.success.data.success) {
        toast.success("Otp has been said to your registered email.")
        yield put({ type: 'AUTH/FORGOT/SEND', payload: response.success.data.data })
      } else {
        toast.error("Something Went Wrong")
      }
      yield put({ type: 'LOADING/SET', payload: false })
    }
  } catch (error) {
    toast.error("Something Went Wrong")
    yield put({ type: 'LOADING/SET', payload: false })
  }

}

export function* getAdminEmailID(action) {
  yield put({ type: 'LOADING/SET', payload: true })

  try {
    const response = yield race({
      success: call(ajaxRequest, {
        method: 'POST',
        url: `admin/getAdminEmailID`,
      }),
      cancel: take('ROUTE/CHANGE')
    });

    if (response.success) {
      if (response.success.data.success) {
        yield put({ type: 'Set/Admin/EmailID', payload: response.success.data.data.data[0].email })
      }
      yield put({ type: 'LOADING/SET', payload: false })
    }
  } catch (error) {
    toast.error("Something Went Wrong")
    yield put({ type: 'LOADING/SET', payload: false })
  }

}

export function* FrogotSubmit(action) {
  yield put({ type: 'LOADING/SET', payload: true })

  try {
    const response = yield race({
      success: call(ajaxRequest, {
        method: 'POST',
        url: `users/forgotPassword`,
        data: action.payload,
      }),
      cancel: take('ROUTE/CHANGE')
    });

    if (response.success) {
      if (response.success.data.success) {
        toast.success("A verification code has been sent to your email.")
        yield put({ type: 'Set/Forgot/Otp', payload: true })
      }
      yield put({ type: 'LOADING/SET', payload: false })
    }
  } catch (error) {
    toast.error("Something Went Wrong")
    yield put({ type: 'LOADING/SET', payload: false })
  }
}

export function* OtpSubmit(action) {
  yield put({ type: 'LOADING/SET', payload: true })

  try {
    const response = yield race({
      success: call(ajaxRequest, {
        method: 'POST',
        url: `users/redeemForgotCode`,
        data: action.payload,
      }),
      cancel: take('ROUTE/CHANGE')
    });

    if (response.success) {
      if (response.success.data.success) {
        toast.success("Otp Verified Successfully.")
        yield put({ type: 'Set/Otp/Submit', payload: response.success.data.data })
      }
      yield put({ type: 'LOADING/SET', payload: false })
    }
  } catch (error) {
    console.log(error.response.data.error)
    toast.error("Please Enter Valid OTP.")
    yield put({ type: 'LOADING/SET', payload: false })
  }
}

export function* submitNewPassword(action) {
  yield put({ type: 'LOADING/SET', payload: true })

  try {
    const response = yield race({
      success: call(ajaxRequest, {
        method: 'POST',
        url: `users/resetPassword`,
        data: action.payload,
      }),
      cancel: take('ROUTE/CHANGE')
    });

    if (response.success) {
      if (response.success.data.success) {
        toast.success("Password has been reset successfully")
        yield put({ type: 'Password/Changed', payload: true })
      }
      yield put({ type: 'LOADING/SET', payload: false })
    }
  } catch (error) {
    toast.error("Something Went Wrong")
    yield put({ type: 'LOADING/SET', payload: false })

  }
}



export function* watchLogin() {
  yield takeEvery('LOGIN', LOGIN)
  yield takeEvery('FORGOT', FORGOT)
  yield takeEvery('get/Admin/EmailID', getAdminEmailID)
  yield takeEvery('Frogot/Submit', FrogotSubmit)
  yield takeEvery('Otp/Submit', OtpSubmit)
  yield takeEvery('Submit/New/Password', submitNewPassword)
}



