export const usersData = (payload) => {
    console.log("usersData")
    return {
        type: "LIST/USERS",
        payload: payload
    }
}
export const pdfExport = (payload) => {
    return {
        type: "users/pdf/Export",
        payload: payload
    }
}
export const acsessChange = (payload) => {
    return {
        type: "acsess/change",
        payload: payload
    }
}
export const bulkPasswordReset = (payload) => {
    return {
        type: "bulk/password/reset",
        payload: payload
    }
}
export const checkBoxDateSet = (payload) => {
    return {
        type: "check/box/date/set",
        payload: payload
    }
}
export const getUserData = (payload) => {
    return {
        type: "get/User/Data",
        payload: payload
    }
}
export const getUsersList = (payload) => {
    return {
        type: "get/Users/List",
        payload: payload
    }
}
export const filteredData = (payload) => {
    return {
        type: "filtered/Data",
        payload: payload
    }
}
export const sendMail = (payload) => {
    return {
        type: "send/mail",
        payload: payload
    }
}
export const selectedAccsess = (payload) => {
    return {
        type: "selected/Accsess",
        payload: payload
    }
}