import { put, takeEvery, race, call, take } from 'redux-saga/effects'
import { ajaxRequest } from "../../Helper/apiCall"
import { toast } from 'react-toastify';


export function* LIST(action) {
  yield put({ type: 'LOADING/SET', payload: true })

  try {
    const response = yield race({
      success: call(ajaxRequest, {
        method: 'POST',
        url: `admin/listUsers`,
        data: action.payload
      }),
      cancel: take('ROUTE/CHANGE')
    });

    if (response.success) {
      yield put({ type: 'USERS/LIST/SET', payload: response.success.data.data })
      yield put({ type: 'LOADING/SET', payload: false })
    }
  } catch (error) {
    toast.error("Something Went Wrong")
    yield put({ type: 'LOADING/SET', payload: false })
  }

}

export function* pdfExport(action) {

  try {
    const response = yield race({
      success: call(ajaxRequest, {
        method: 'POST',
        url: `admin/listUsers`,
        data: action.payload
      }),
      cancel: take('ROUTE/CHANGE')
    });
    if (response.success) {
      yield put({ type: 'users/pdfExport/SET', payload: response.success.data.data })
    }
  } catch (error) {
    toast.error("Something Went Wrong")
  }
}

export function* acsessChange(action) {
  yield put({ type: 'LOADING/SET', payload: true })

  try {
    const response = yield race({
      success: call(ajaxRequest, {
        method: 'POST',
        url: `admin/acsessChange`,
        data: action.payload
      }),
      cancel: take('ROUTE/CHANGE')
    });
    if (response.success) {
      yield put({ type: 'acsess/change/set', payload: true })
      if(!action.payload.status){
        toast.success("Access Revoked Successfully.")
      }else{
        toast.success("Access Resume Successfully.")
      }
      yield put({ type: 'LOADING/SET', payload: false })

    }
  } catch (error) {
    toast.error("Something Went Wrong")
    yield put({ type: 'LOADING/SET', payload: false })
  }
}

export function* bulkPasswordReset(action) {
  yield put({ type: 'LOADING/SET', payload: true })

  try {
    const response = yield race({
      success: call(ajaxRequest, {
        method: 'POST',
        url: `admin/bulkPasswordReset`,
        data: action.payload
      }),
      cancel: take('ROUTE/CHANGE')
    });
    if (response.success) {
      yield put({ type: 'bulk/Password/Reset/set', payload: true })
      toast.success("Password has been reset successfully")
      yield put({ type: 'LOADING/SET', payload: false })

    }
  } catch (error) {
    toast.error("Something Went Wrong")
    yield put({ type: 'LOADING/SET', payload: false })
  }
}

export function* getUserData(action) {
  yield put({ type: 'LOADING/SET', payload: true })

  try {
    const response = yield race({
      success: call(ajaxRequest, {
        method: 'POST',
        url: `admin/getUserData`,
        data: action.payload
      }),
      cancel: take('ROUTE/CHANGE')
    });
    if (response.success) {
      yield put({ type: 'get/User/Data/set', payload: response.success.data.data })
      yield put({ type: 'LOADING/SET', payload: false })

    }
  } catch (error) {
    toast.error("Something Went Wrong")
    yield put({ type: 'LOADING/SET', payload: false })
  }
}

export function* getUsersList(action) {
  yield put({ type: 'LOADING/SET', payload: true })

  try {
    const response = yield race({
      success: call(ajaxRequest, {
        method: 'POST',
        url: `admin/getUserList`,
        data: action.payload
      }),
      cancel: take('ROUTE/CHANGE')
    });
    if (response.success) {
      yield put({ type: 'get/Users/List/set', payload: response.success.data.data })
      yield put({ type: 'LOADING/SET', payload: false })

    }
  } catch (error) {
    toast.error("Something Went Wrong")
    yield put({ type: 'LOADING/SET', payload: false })
  }
}

export function* sendMail(action) {
  yield put({ type: 'LOADING/SET', payload: true })

  try {
    const response = yield race({
      success: call(ajaxRequest, {
        method: 'POST',
        url: `admin/sendmail`,
        isCustomDataOrImg: true,
        CustomDataOrImg: action.payload
      }),
      cancel: take('ROUTE/CHANGE')
    });
    if (response.success) {
      yield put({ type: 'send/mail/set', payload: response.success.data.data })
      toast.success("Mail send Successfully.")
      yield put({ type: 'LOADING/SET', payload: false })

    }
  } catch (error) {
    console.log("error",error)
    toast.error("Something Went Wrong")
    yield put({ type: 'LOADING/SET', payload: false })
  }
}


export function* watchUsers() {
  yield takeEvery('LIST/USERS', LIST)
  yield takeEvery('users/pdf/Export', pdfExport)
  yield takeEvery('acsess/change', acsessChange)
  yield takeEvery('bulk/password/reset', bulkPasswordReset)
  yield takeEvery('get/User/Data', getUserData)
  yield takeEvery('get/Users/List', getUsersList)
  yield takeEvery('send/mail', sendMail)
}


