import React from "react";
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'
import { connect } from 'react-redux';
import { subscriptionData } from './action'


class Subscription extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }

    float2int(value) {
        return value | 0;
    }

    render() {
        let { listData, count } = this.props;

        let page = (count / 10)

        page = page == count ? this.float2int(page) : this.float2int(page + 1)
        if(count <= 10){
            page = 1
        }

        const columns = [{
            Header: 'id',
            accessor: 'id' ,
        }, {
            Header: 'email',
            accessor: 'email',
            // Cell: props => <span className='number'>{props.value}</span> // Custom cell components!
        }, {
            Header: 'name',
            accessor: 'name',
        },
        {
            Header: 'Subscription Ends',
            accessor: 'subscription_ends',
        },
        {
            Header: 'Subscription Start',
            accessor: 'subscription_start',
        },
        {
            Header: 'data',
            accessor: 'tranfer.sum',
        },
        //  {
        //     id: 'email', // Required because our accessor is not a string
        //     Header: 'email',
        //     accessor: d => d // Custom value accessors!
        // }, {
        //     id: 'subscriptionStart', // Required because our accessor is not a string
        //     Header: 'Subscription Start',
        //     accessor: d => d // Custom value accessors!
        // }, {
        //     id: 'size', // Required because our accessor is not a string
        //     Header: 'size',
        //     accessor: d => d // Custom value accessors!
        // }, {
        //     id: 'subscriptionEnd', // Required because our accessor is not a string
        //     Header: 'Subscription End',
        //     accessor: d => d // Custom value accessors!
        // }
    ]

        return <ReactTable
            data={listData}
            columns={columns}
            pages={page}
            defaultPageSize={10}
            pageSizeOptions={[10]}
            loading={listData ? false : true}
            manual // informs React Table that you'll be handling sorting and pagination server-side
            onFetchData={(state, instance) => {
                console.log(">>>>>>>>>", state)
                this.props.subscriptionData({ page: state.page + 1 })
            }}

        />
    }
}
const mapStateToProps = (state, ownProps) => {
    console.log("state", state)
    return {
        listData: state.Subscription?.listData?.data,
        count: state.Subscription?.listData?.count,
    }
}
const mapDispatchToProps = dispatch => {

    return ({
        subscriptionData: (value) => { dispatch(subscriptionData(value)); },

    })
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscription);
