import React from "react";
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'
import { connect } from 'react-redux';
import { usersData, acsessChange, checkBoxDateSet, selectedAccsess } from './action'
import { Link } from 'react-router-dom';
import { change, initialize } from 'redux-form'

let userCallStatus = true
let page = 1
class UsersTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            checkBox: [],
            acsess: ''
        }
    }

    float2int(value) {
        return value | 0;
    }

    buttonClick = () => {
        this.props.acsessChange({ userIds: this.state.checkBox, status: this.props.selectedAccsessVal ? 0 : 1})
        this.setState({ checkBox: [] })
        this.props.userListData.map((data, index)=>{
            if (this.state.checkBox.includes(data.user_id)) {
                data.acsess = this.props.selectedAccsessVal ? 0 : 1
            }
           
            return data
        })
    }

    componentDidMount = () => {
        if (userCallStatus) {
            userCallStatus = false
        }
    }

    componentDidUpdate = (prevProps) => {
        let acsessClass = document.getElementsByClassName("acsessClass")
        if (acsessClass) {
            for (let j = 0; j < acsessClass.length; j++) {
                acsessClass[j].disabled = false;
            }
        }
        if (this.state.checkBox[0]) {
            if (this.state.acsess == "no") {
                let acsessClass = document.getElementsByClassName("acsess_enable")
                for (let i = 0; i < acsessClass.length; i++) {
                    acsessClass[i].disabled = true;
                }
                let acsess_disable = document.getElementsByClassName("acsess_disable")
                for (let i = 0; i < acsess_disable.length; i++) {
                    acsess_disable[i].disabled = false;
                }
            } else if (this.state.acsess == "yes") {
                let acsessClass = document.getElementsByClassName("acsess_enable")
                for (let i = 0; i < acsessClass.length; i++) {
                    acsessClass[i].disabled = false;
                }
                let acsess_enable = document.getElementsByClassName("acsess_disable")
                for (let i = 0; i < acsess_enable.length; i++) {
                    acsess_enable[i].disabled = true;
                }
            }
        }

        if (prevProps.searchData != this.props.searchData) {
            if (this.props.searchData == "") {
                this.props.usersData({ page: 1, userStatus: this.props.userStatus })

            } else {
                this.props.usersData({ searchStatus: true, search: this.props.searchData, page: 1, userStatus: this.props.userStatus })
            }
            page = 1
        }
        if (prevProps.userStatus != this.props.userStatus) {
            this.props.usersData({ page: 1, userStatus: this.props.userStatus })
        }
        if (prevProps.buttonClick != this.props.buttonClick) {
            this.buttonClick();
        }
        if (prevProps.acsesschangeStatus != this.props.acsesschangeStatus) {
            this.setState({ checkBox: [] })
        }
        if (prevProps.bulkPasswordResetStatus != this.props.bulkPasswordResetStatus) {
            this.setState({ checkBox: [] })
            this.props.resetFieldValue();
            document.getElementById("cancelTableButton").click()
        }
    }

    getTrProps = (state, rowInfo, instance) => {
        if (rowInfo && rowInfo?.original?.acsess != 1) {
            return {
                className: "status-refused",
                style: {
                    background: '#dddddd'
                }
            }
        }
        return {};
    }

    render() {
        // console.log("sssssssssssss", this.props.selectedAccsess)
        if (!this.state.checkBox[0]) {
            if (document.getElementsByClassName("actionButton")[0]) {
                document.getElementsByClassName("actionButton")[0].style.display = "none"
                document.getElementsByClassName("actionButton")[1].style.display = "none"
            }
        } else {
            document.getElementsByClassName("actionButton")[0].style.display = "flex"
            document.getElementsByClassName("actionButton")[1].style.display = "flex"
        }
        this.props.checkBoxDateSet({ data: this.state.checkBox })

        let { userListData, userCount } = this.props;
        let allCheck = document.getElementById("usersAllCheck")
        if (allCheck) {
            allCheck.checked = true
            userListData && userListData.map((elem, index) => {
                if (!this.state.checkBox.includes(elem.user_id)) {
                    allCheck.checked = false
                }
            })
        }

        page = (userCount / 10)
        page = page == userCount ? this.float2int(page) : this.float2int(page + 1)
        if(userCount <= 10){
            page = 1
        }
        const columns = [
            {
                Header: state => {
                    return (
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="usersAllCheck"
                            onClick={(e) => {
                                if (e.target.checked) {
                                    let datMap = state.data.map((elem) => {
                                        if (!this.state.checkBox.includes(elem._original.user_id)) {
                                            return elem._original.user_id
                                        }
                                    })
                                    this.setState({ checkBox: [...this.state.checkBox, ...datMap] })
                                } else {
                                    let datMaps = state.data.map((elem) => {
                                        return elem._original.user_id

                                    })
                                    this.setState({
                                        checkBox: this.state.checkBox.filter(function (box) {
                                            if (!datMaps.includes(box)) {
                                                return true
                                            }
                                        })
                                    });
                                }
                            }}
                        />
                    )
                },
                Cell: (cell) => {
                    return (<input
                        type="checkbox"
                        checked={this.state.checkBox.includes(cell.original.user_id)}
                        className={`form-check-input acsessClass ${cell.original.acsess ? "acsess_enable" : "acsess_disable"}`}
                        id={cell.original.user_id}
                        onClick={(e) => {
                            this.setState({ acsess: cell.original.acsess ? "yes" : "no" })
                            this.props.selectedAccsess(cell.original.acsess)
                            if (!this.state.checkBox.includes(cell.original.user_id)) {
                                this.setState({ checkBox: [...this.state.checkBox, cell.original.user_id] })
                            } else {
                                this.setState({
                                    checkBox: this.state.checkBox.filter(function (box) {
                                        return box !== cell.original.user_id
                                    })
                                });
                            }
                        }}
                    />)
                }
            },
            {
                Header: 'S.NO',
                accessor: 'id',
            }, {
                Header: 'Name',
                accessor: 'name',
            }, {
                Header: 'Email',
                accessor: 'email',
            },
            {
                Header: 'Files',
                accessor: 'file',
            },
            {
                Header: 'Data',
                accessor: 'data',
            },
            {
                Header: 'Date of',
                accessor: 'created',
            },
            {
                Header: 'Details',
                accessor: 'details',
                Cell: (cell) => {
                    return <Link to={`/users/user-profile`} state={{ id: cell.original.user_id }}>
                        <div onClick={() => {
                        }}>
                            <svg
                                id="eye-line"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    id="Path_41"
                                    data-name="Path 41"
                                    d="M0,0H24V24H0Z"
                                    fill="none"
                                />
                                <path
                                    id="Path_42"
                                    data-name="Path 42"
                                    d="M12,3a11,11,0,0,1,10.819,9A11,11,0,0,1,1.181,12,11,11,0,0,1,12,3Zm0,16a9,9,0,0,0,8.777-7A9,9,0,0,0,3.223,12,9.005,9.005,0,0,0,12,19Zm0-2.5A4.5,4.5,0,1,1,16.5,12,4.5,4.5,0,0,1,12,16.5Zm0-2A2.5,2.5,0,1,0,9.5,12,2.5,2.5,0,0,0,12,14.5Z"
                                    fill="#ec3040"
                                />
                            </svg>
                        </div>
                    </Link>
                }
            }
        ]

        return <ReactTable
            data={userListData}
            columns={columns}
            pages={page}
            defaultPageSize={10}
            pageSizeOptions={[10]}
            loading={userListData ? false : true}
            getTrProps={this.getTrProps}
            manual // informs React Table that you'll be handling sorting and pagination server-side
            onFetchData={(state) => {
                if (this.props.searchData == "") {
                    this.props.usersData({ page: state.page + 1, userStatus: this.props.userStatus })
                } else {
                    this.props.usersData({ searchStatus: true, search: this.props.searchData, page: state.page + 1, userStatus: this.props.userStatus })
                }
                this.setState({
                    page: state.page + 1
                })
            }}
        />
    }
}
const mapStateToProps = (state, ownProps) => {
    console.log("state", state)
    return {
        userListData: state.user?.userListData?.data,
        userCount: state.user?.userListData?.count,
        acsesschangeStatus: state.user?.acsesschange,
        bulkPasswordResetStatus: state.user?.bulkPasswordResetStatus,
        selectedAccsessVal: state.user?.selectedAccsess
    }
}
const mapDispatchToProps = dispatch => {
    
    return ({
        selectedAccsess: (value) => { dispatch(selectedAccsess(value)); },
        usersData: (value) => { dispatch(usersData(value)); },
        acsessChange: (value) => { dispatch(acsessChange(value)); },
        checkBoxDateSet: (value) => { dispatch(checkBoxDateSet(value)); },
        changeFieldValue: function (field, value) {
            dispatch(change("_TableComponent", field, value))
        },
        resetFieldValue: function () {
            dispatch(initialize("_TableComponent"))
        }
    })
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersTable);
