import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.scss";
import App from "./App";
import store from "./store";
import { Provider } from "react-redux"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const container = document.getElementById("root");
const root = createRoot(container);
if (process.env.REACT_APP_API_MODE == 'production') {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
}
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <Provider store={store}>
        <ToastContainer autoClose={2000} />
        <App />
      </Provider>
    </React.StrictMode>
  </BrowserRouter>
);
