import React from "react";
import "./Heading.scss";

function Heading({ varient, value }) {
  return (
    <>
      <h3 className={`defaultHeading ${varient}`}>{value}</h3>
    </>
  );
}

export default Heading;
