import React from "react";
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'
import { connect } from 'react-redux';
import { historyData } from './action'
import { Link } from 'react-router-dom';

class History extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1
        }
    }

    float2int(value) {
        return value | 0;
    }

    componentDidUpdate = (prevProps) => {
        console.log(">>>>>>>>>>>>>", prevProps.searchData, this.props.searchData, prevProps.searchData == this.props.searchData)
        if (prevProps.searchData != this.props.searchData) {
            if (this.props.searchData == "") {
                this.props.historyData({ page: 1 })

            } else {
                this.props.historyData({ searchStatus: true, search: this.props.searchData, page: 1 })
            }
        }

    }

    render() {
        let { hisListData, hirCount } = this.props;
        let page = (hirCount / 10)
        page = page == hirCount ? this.float2int(page) : this.float2int(page + 1)
        console.log("count>>>", hirCount, page, page == hirCount)


        const columns = [{
            Header: 'S.NO',
            accessor: 'id',
        }, {
            Header: 'Name',
            accessor: 'name',
            // Cell: props => <span className='number'>{props.value}</span> // Custom cell components!
        }, {
            Header: 'Email',
            accessor: 'email',
        },
        // {
        //     Header: 'File Name',
        //     accessor: 'file_Name',
        // },
        {
            Header: 'Data Transferred',
            accessor: 'data_transferred',
        },
        {
            Header: 'Transfer Date',
            accessor: 'transfer_date',
        },
        {
            Header: 'Details',
            accessor: 'details',
            Cell: (cell) => {
                return <Link to={`/transaction-details?transfer_id=${cell.original.transfer_id}`} >
                    <div>
                        <svg
                            id="eye-line"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path
                                id="Path_41"
                                data-name="Path 41"
                                d="M0,0H24V24H0Z"
                                fill="none"
                            />
                            <path
                                id="Path_42"
                                data-name="Path 42"
                                d="M12,3a11,11,0,0,1,10.819,9A11,11,0,0,1,1.181,12,11,11,0,0,1,12,3Zm0,16a9,9,0,0,0,8.777-7A9,9,0,0,0,3.223,12,9.005,9.005,0,0,0,12,19Zm0-2.5A4.5,4.5,0,1,1,16.5,12,4.5,4.5,0,0,1,12,16.5Zm0-2A2.5,2.5,0,1,0,9.5,12,2.5,2.5,0,0,0,12,14.5Z"
                                fill="#ec3040"
                            />
                        </svg>
                    </div>
                </Link>
            }// Custom cell components!
        }
        ]

        return <ReactTable
            data={hisListData}
            columns={columns}
            pages={page}
            defaultPageSize={10}
            pageSizeOptions={[10]}
            loading={hisListData ? false : true}
            manual // informs React Table that you'll be handling sorting and pagination server-side
            onFetchData={(state, instance) => {
                if (this.props.searchData == "") {
                    this.props.historyData({ page: state.page + 1 })
                } else {
                    console.log("searchData", this.props.searchData)
                    this.props.historyData({ searchStatus: true, search: this.props.searchData, page: state.page + 1 })
                }
                this.setState({
                    page: state.page + 1
                })
            }}

        />
    }
}
const mapStateToProps = (state, ownProps) => {
    console.log("state", state)
    return {
        hisListData: state.History?.historyListData?.data,
        hirCount: state.History?.historyListData?.count,
    }
}
const mapDispatchToProps = dispatch => {

    return ({
        historyData: (value) => { dispatch(historyData(value)); },

    })
};

export default connect(mapStateToProps, mapDispatchToProps)(History);
