import React, { useEffect, useState } from "react";
import "./UserProfile.scss";
import Heading from "../../components/Heading/Heading";
import ProfileBg from "../../assets/profile-bg.png";
import UserImg from "../../assets/smith.png";
import RevokeAccess from "../../assets/Hiring-rafiki.png";
import { FaCheckCircle } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { connect } from 'react-redux';
import { getUserData, acsessChange, bulkPasswordReset } from '../Users/action'
import SecurityImage from "../../assets/Security.png";
import { Field, reduxForm } from 'redux-form'
import { isEmpty } from '../../Helper/validation'
import { inputField } from "../../components/Input/fields";
import { initialize } from 'redux-form'


const validate = (values) => {
  const errors = {}
  if (isEmpty(values.confirm_password)) {
    errors.confirm_password = 'Enter your confirm password.';
  }
  if (isEmpty(values.password)) {
    errors.password = 'Enter your Password.';
  }
  if (values.password != values.confirm_password) {
    errors.confirm_password = 'Password And confirm password Should be same.';
  }
  return errors

}

function UserProfile(props) {
  let { userDataFields, bulkPasswordResetStatus } = props;
  let [status, UpdateStaus] = useState(false)
  const location = useLocation();
  const { id } = location.state;

  useEffect(() => {
    props.getUserData({ id })
  }, [])

  useEffect(() => {
    if (bulkPasswordResetStatus) {
      props.resetFieldValue();
    }
  }, [bulkPasswordResetStatus])

  useEffect(() => {
    if (userDataFields) {
      UpdateStaus(userDataFields[0]?.acsess ? true : false)
    }
  }, [userDataFields])


  const handleToast = () => {
    props.acsessChange({ userIds: [id], status: !status ? 1 : 0 })
    UpdateStaus(!status)

  };
  console.log("status", status)
  return (
    <>

      <div className="dashboard-wrapper user-profile">
        <div>
          <Heading value="Users" varient="h2" />
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard">Dashboard</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/users">Users</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <span>User Profile</span>
              </li>
            </ol>
          </nav>
        </div>
        <div className="user-profile-content">
          <figure>
            <img className="profile-bg" src={ProfileBg} alt="profil bg" />
          </figure>
          <div className="user-profile">
            <div className="user-img-wrapper">
              <figure>
                <img className="user-img profile_image" src={userDataFields && userDataFields[0]?.avatar  ? `${process.env.REACT_APP_API_URL}/avatar/${userDataFields[0]?.avatar}` : UserImg} alt="profil bg" />
              </figure>
              <h3 className="user-name">{userDataFields && `${userDataFields[0]?.first_name} ${userDataFields[0]?.last_name}`}</h3>
            </div>
            <div className="user-btns">
              <button
                className="btn transparent-btn"
                data-bs-toggle="modal"
                data-bs-target="#resumeAccess"
              >
                {!status ? "Resume User Access" : "Revoke User Access"}
              </button>
              <button
                className="btn transparent-btn1"
                data-bs-toggle="modal"
                data-bs-target="#passwordreset"
              >
                Master Password Reset
              </button>
            </div>

            <div className="modal fade" id="passwordreset" tabIndex="-1"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="modal-head">
                      <img src={SecurityImage} alt="security access" className="security" />
                    </div>
                    <div className="content">
                      <p className="sm-bold">
                        Are you sure you want to reset
                        <br /> your password?
                      </p>
                      <div className="btns">
                        <button
                          type="button"
                          className="btn transparent-btn"
                          data-bs-dismiss="modal"
                        >
                          No
                        </button>
                        <button
                          type="button"
                          className="btn transparent-btn1"
                          data-bs-target="#passwordresetform"
                          data-bs-toggle="modal"
                          data-bs-dismiss="modal"
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="modal fade" id="passwordresetform" tabIndex="-1">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="modal-head">
                      <img src={SecurityImage} alt="security access" className="security" />
                    </div>
                    <div className="content">
                      <p className="mb-4 sm-bold">Reset Password?</p>
                      <form
                        onSubmit={props.handleSubmit((values) => {
                          props.bulkPasswordReset({ ...values, usersId: [id] })
                          document.getElementById("cancelButton").click()

                        })}
                        className="d-flex flex-column">
                        <Field name="password" placeholder="New password" component={inputField} type="password" styleClass="password" />
                        <Field name="confirm_password" placeholder="Confirm password" component={inputField} type="password" styleClass="confirm-password" />
                        <div className="btns">
                          <button
                            type="button"
                            className="btn transparent-btn"
                            data-bs-dismiss="modal"
                            id="cancelButton"
                            onClick={()=>{
                              props.resetFieldValue();
                            }}
                          >
                            Cancel
                          </button>
                          <button type="submit" className="btn transparent-btn1" >
                            Proceed
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal fade" id="resumeAccess" tabIndex="-1">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="modal-head">
                      <img src={RevokeAccess} alt="RevokeAccess" />
                    </div>
                    <div className="content">
                      <p className="mb-2 alertText">Alert</p>
                      <p>{!status ? "Resume Access" : "Revoke Access"}</p>
                      <div className="btns">
                        <button
                          type="button"
                          className="btn transparent-btn"
                          data-bs-dismiss="modal"
                        >
                          No
                        </button>
                        <button
                          type="button"
                          className="btn transparent-btn1"
                          data-bs-dismiss="modal"
                          onClick={() => handleToast()}
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="toast-position p-3">
            <div
              className="toast"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
              id="revokeToast"
            >
              <div className="d-flex">
                <div className="toast-body">
                  <FaCheckCircle className="me-2 i" />
                  Access Revoked
                </div>
                <button
                  type="button"
                  className="btn-close btn-close-white me-2 m-auto"
                  data-bs-dismiss="toast"
                  aria-label="Close"
                ></button>
              </div>
            </div>
          </div>
          <div className="user-data">
            <div className="table-responsive">
              <table className="table user-profile-table">
                <thead>
                  <tr>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="21"
                        viewBox="0 0 16 21"
                      >
                        <path
                          id="Path_249"
                          data-name="Path 249"
                          d="M4,22a8,8,0,1,1,16,0H18A6,6,0,1,0,6,22Zm8-9a6,6,0,1,1,6-6A6,6,0,0,1,12,13Zm0-2A4,4,0,1,0,8,7,4,4,0,0,0,12,11Z"
                          transform="translate(-4 -1)"
                          fill="#2268c0"
                        />
                      </svg>
                      <h3>First Name</h3>
                    </td>
                    <td>:</td>
                    <td>{userDataFields && userDataFields[0]?.first_name}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="21"
                        viewBox="0 0 16 21"
                      >
                        <path
                          id="Path_249"
                          data-name="Path 249"
                          d="M4,22a8,8,0,1,1,16,0H18A6,6,0,1,0,6,22Zm8-9a6,6,0,1,1,6-6A6,6,0,0,1,12,13Zm0-2A4,4,0,1,0,8,7,4,4,0,0,0,12,11Z"
                          transform="translate(-4 -1)"
                          fill="#2268c0"
                        />
                      </svg>
                      <h3>Last Name</h3>
                    </td>
                    <td>:</td>
                    <td>{userDataFields && userDataFields[0]?.last_name}</td>
                  </tr>
                  <tr>
                    <td>
                      <svg
                        id="profile-line"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          id="Path_251"
                          data-name="Path 251"
                          d="M0,0H24V24H0Z"
                          fill="none"
                        />
                        <path
                          id="Path_252"
                          data-name="Path 252"
                          d="M2,3.993A1,1,0,0,1,2.992,3H21.008A.993.993,0,0,1,22,3.993V20.007a1,1,0,0,1-.992.993H2.992A.993.993,0,0,1,2,20.007ZM4,5V19H20V5ZM6,7h6v6H6ZM8,9v2h2V9ZM6,15H18v2H6Zm8-8h4V9H14Zm0,4h4v2H14Z"
                          fill="#2268c0"
                        />
                      </svg>
                      <h3>User ID</h3>
                    </td>
                    <td>:</td>
                    <td>{userDataFields && userDataFields[0]?.user_id}</td>
                  </tr>
                  <tr>
                    <td>
                      <svg
                        id="mail-line"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          id="Path_9"
                          data-name="Path 9"
                          d="M0,0H24V24H0Z"
                          fill="none"
                        />
                        <path
                          id="Path_10"
                          data-name="Path 10"
                          d="M3,3H21a1,1,0,0,1,1,1V20a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V4A1,1,0,0,1,3,3ZM20,7.238l-7.928,7.1L4,7.216V19H20ZM4.511,5l7.55,6.662L19.5,5Z"
                          fill="#2268c0"
                        />
                      </svg>
                      <h3> Email ID</h3>
                    </td>
                    <td>:</td>
                    <td>
                      {" "}
                      <a href="">{userDataFields && userDataFields[0]?.email}</a>
                    </td>
                  </tr>
                  {/* <tr>
                      <td>
                        <svg
                          id="phone-line"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path
                            id="Path_253"
                            data-name="Path 253"
                            d="M0,0H24V24H0Z"
                            fill="none"
                          />
                          <path
                            id="Path_254"
                            data-name="Path 254"
                            d="M9.366,10.682a10.556,10.556,0,0,0,3.952,3.952L14.2,13.4a1,1,0,0,1,1.294-.3,11.422,11.422,0,0,0,4.583,1.364,1,1,0,0,1,.921,1v4.462a1,1,0,0,1-.9,1A15.509,15.509,0,0,1,3.082,3.9a1,1,0,0,1,1-.9H8.539a1,1,0,0,1,1,.921A11.422,11.422,0,0,0,10.9,8.5,1,1,0,0,1,10.6,9.8l-1.238.884Zm-2.522-.657,1.9-1.357A13.41,13.41,0,0,1,7.647,5H5.01C5,5.166,5,5.333,5,5.5A13.5,13.5,0,0,0,18.5,19c.167,0,.334,0,.5-.01V16.353a13.41,13.41,0,0,1-3.668-1.1l-1.357,1.9a12.442,12.442,0,0,1-1.588-.75l-.058-.033a12.556,12.556,0,0,1-4.7-4.7l-.033-.058a12.442,12.442,0,0,1-.75-1.588Z"
                            fill="#2268c0"
                          />
                        </svg>
                        <h3> Phone Number</h3>
                      </td>
                      <td>:</td>
                      <td>+44 1234 567 890</td>
                    </tr> */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

UserProfile = reduxForm({
  form: '_UserProfile',
  validate,
  destroyOnUnmount: true,
  enableReinitialize: true
})(UserProfile);

const mapStateToProps = (state, ownProps) => {
  return {
    userDataFields: state.user?.userDataFields?.data,
    bulkPasswordResetStatus: state.user?.bulkPasswordResetStatus,
  }
}
const mapDispatchToProps = dispatch => {

  return ({
    getUserData: (value) => { dispatch(getUserData(value)); },
    acsessChange: (value) => { dispatch(acsessChange(value)); },
    bulkPasswordReset: (value) => { dispatch(bulkPasswordReset(value)); },
    resetFieldValue: function () {
      dispatch(initialize("_UserProfile"))
    }
  })
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
