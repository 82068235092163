import React from "react";
import SecurityImage from "../../assets/Security.png";
import RevokeAccess from "../../assets/Hiring-rafiki.png";
import { FaCheckCircle } from "react-icons/fa";
import DataTable from './UsersTable'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux';
import { isEmpty } from '../../Helper/validation'
import { inputField } from "../../components/Input/fields";
import { bulkPasswordReset } from "./action"

const Users = [];
const validate = (values) => {
  const errors = {}

  if (isEmpty(values.confirm_password)) {
    errors.confirm_password = 'Enter your confirm password.';
  }
  if (isEmpty(values.password)) {
    errors.password = 'Enter your Password.';
  }
  if (values.password != values.confirm_password) {
    errors.confirm_password = 'Password And confirm password Should be same.';
  }
  return errors

}

class TableComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      List: Users,
      MasterChecked: false,
      SelectedList: [],
      buttonClick: 0,
    };
  }

  // Select/ UnSelect Table rows
  onMasterCheck(e) {
    let tempList = this.state.List;
    // Check/ UnCheck All Items
    tempList.map((user) => (user.selected = e.target.checked));

    //Update State
    this.setState({
      MasterChecked: e.target.checked,
      List: tempList,
      SelectedList: this.state.List.filter((e) => e.selected),
    });
  }

  // Update List Item's state and Master Checkbox State
  onItemCheck(e, item) {
    let tempList = this.state.List;
    tempList.map((user) => {
      if (user.id === item.id) {
        user.selected = e.target.checked;
      }
      return user;
    });

    //To Control Master Checkbox State
    const totalItems = this.state.List.length;
    const totalCheckedItems = tempList.filter((e) => e.selected).length;

    // Update State
    this.setState({
      MasterChecked: totalItems === totalCheckedItems,
      List: tempList,
      SelectedList: this.state.List.filter((e) => e.selected),
    });
  }

  // Event to get selected rows(Optional)
  getSelectedRows() {
    this.setState({
      SelectedList: this.state.List.filter((e) => e.selected),
    });
  }

  handlePasswordToast() {
    var element = document.getElementById("resetpassword");
    element.classList.add("show");
  };
  handleToast() {
    var element = document.getElementById("revokeToast");
    element.classList.add("show");
  };

  render() {
    return (
      <div className="table-wrap">
        <div className="top-btns">
          {this.props.selectedAccsess ?
            <button
              type="button"
              className="btn transparent-btn actionButton"
              data-bs-toggle="modal"
              data-bs-target="#revokeAccess"
            >
              Revoke Access
            </button>
            :
            <button
              type="button"
              className="btn transparent-btn actionButton"
              data-bs-toggle="modal"
              data-bs-target="#revokeAccess"
            >
              Resume Access
            </button>
          }
          <a
            type="button"
            className="btn transparent-btn1 actionButton"
            data-bs-toggle="modal"
            href="#passwordreset" role="button"
          >
            Password Reset
          </a>
        </div>

        <div className="modal fade" id="revokeAccess" tabIndex="-1"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="modal-head">
                  <img src={RevokeAccess} alt="RevokeAccess" />
                </div>
                <div className="content">
                  <p className="mb-2 alertText">
                    Alert
                  </p>
                  {this.props.selectedAccsess ? <p>Revoke access for the user</p> : <p>Resume access for the user</p>}
                  
                  <div className="btns">
                    <button
                      type="button"
                      className="btn transparent-btn"
                      data-bs-dismiss="modal"
                    >
                      No
                    </button>
                    <button
                      type="button"
                      className="btn transparent-btn1"
                      data-bs-dismiss="modal"
                      // onClick={() => this.handleToast()}
                      onClick={() => { this.setState({ buttonClick: this.state.buttonClick + 1 }) }}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="toast-position p-3">
          <div
            className="toast"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            id="revokeToast"
          >
            <div className="d-flex">
              <div className="toast-body">
                <FaCheckCircle className="me-2 i" />
                Access Revoked
              </div>
              <button
                type="button"
                className="btn-close btn-close-white me-2 m-auto"
                data-bs-dismiss="toast"
                aria-label="Close"
              ></button>
            </div>
          </div>
        </div>

        <div className="modal fade" id="passwordreset" tabIndex="-1"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="modal-head">
                  <img src={SecurityImage} alt="security access" className="security" />
                </div>
                <div className="content">
                  <p className="sm-bold">
                    Are you sure you want to reset
                    <br /> your password?
                  </p>
                  <div className="btns">
                    <button
                      type="button"
                      className="btn transparent-btn"
                      data-bs-dismiss="modal"
                    >
                      No
                    </button>
                    <button
                      type="button"
                      className="btn transparent-btn1"
                      data-bs-target="#passwordresetform"
                      data-bs-toggle="modal"
                      data-bs-dismiss="modal"
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="passwordresetform" tabIndex="-1">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="modal-head">
                  <img src={SecurityImage} alt="security access" className="security" />
                </div>
                <div className="content">
                  <p className="mb-4 sm-bold">Reset Password?</p>
                  <form
                    onSubmit={this.props.handleSubmit((values) => {
                      this.props.bulkPasswordReset({ ...values, usersId: this.props.checkBoxData })
                    })}
                    className="d-flex flex-column">
                    <Field name="password" placeholder="New password" component={inputField} type="password" styleClass="password" />
                    <Field name="confirm_password" placeholder="Confirm password" component={inputField} type="password" styleClass="confirm-password" />
                    <div className="btns">
                      <button
                        type="button"
                        className="btn transparent-btn"
                        data-bs-dismiss="modal"
                        id="cancelTableButton"
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn transparent-btn1" >
                        Proceed
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="toast-position p-3">
          <div
            className="toast"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            id="resetpassword"
          >
            <div className="d-flex">
              <div className="toast-body">
                <FaCheckCircle className="me-2 i" />
                Password reset successfuly
              </div>
              <button
                type="button"
                className="btn-close btn-close-white me-2 m-auto"
                data-bs-dismiss="toast"
                aria-label="Close"
              ></button>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <DataTable searchData={this.props.searchBox} userStatus={this.props.userStatus} buttonClick={this.state.buttonClick} />
        </div>
      </div>
    );
  }
}

TableComponent = reduxForm({
  form: '_TableComponent',
  validate,
  destroyOnUnmount: true,
  enableReinitialize: true
})(TableComponent);

const mapStateToProps = (state, ownProps) => {
  console.log("state", state)
  return {
    checkBoxData: state.user?.checkData?.data,
    selectedAccsess: state.user?.selectedAccsess
  }
}
const mapDispatchToProps = dispatch => {

  return ({
    bulkPasswordReset: (value) => { dispatch(bulkPasswordReset(value)); },

  })
};

export default connect(mapStateToProps, mapDispatchToProps)(TableComponent);
