import React, { Component, PropTypes } from "react";
import RichTextEditor from "react-rte";

class TextEditor extends Component {
  //   static propTypes = {
  //     onChange: React.PropTypes.func,
  //   };
  constructor(props) {
    // console.log(props.value)
    super(props);
    this.state = {
      value: RichTextEditor.createEmptyValue(),
    };
  }

  onChange = (value) => {
    this.setState({ value });
    // if (this.props.onChange) {
    // console.log(value);
    // this.props.onChange(value.toString("html"));
    // }
    this.props.update({ ...this.props.data, message: value.toString('html') })

  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.data?.message != this.props.data?.message) {
      if (!this.props.data?.message) {
        this.setState({ value: RichTextEditor.createEmptyValue() });
      }

    }
  }

  render() {

    // console.log("LLLLLLLLLL", this.props.data.message)
    return (
      <RichTextEditor
        placeholder="Type message here..."
        value={this.state.value}
        onChange={this.onChange}
      />
    );
  }
}

export default TextEditor;
