import React from "react";



export function inputField({ input, disabled, label, id, type, placeholder, styleClass, meta: { touched, error, warning } }) {

    type = type || input.type;
  
    return <>
      {(label && (type == 'text' || type == 'password')) && <label>{label}</label>}
      <React.Fragment>
        <input {...input} styleClass={styleClass} id={id} placeholder={placeholder} disabled={disabled} type={type} />
        {(label && (type != 'text' && type != 'password')) && <label>{label}</label>}
        {touched && ((error && <span className="error" style={{ color: 'red' }}>{error}</span>) || (warning && <span>{warning}</span>))}
      </React.Fragment>
    </>
  }