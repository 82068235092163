export const historyData = (payload) => {
    return {
        type: "LIST/HISTORY",
        payload: payload
    }
}
export const pdfExport = (payload) => {
    return {
        type: "history/pdf/Export",
        payload: payload
    }
}
export const historyDetails = (payload) => {
    return {
        type: "history/Details",
        payload: payload
    }
}