import React from "react";
import "./Tags.scss";

class Tags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      value: "",
      error: null,
    };
  }

  handleKeyDown = (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();
      let value = this.state.value.trim();
      if (value && this.isValid(value)) {
        this.props.update([...this.props.items, { email: this.state.value }])
        this.setState({ value: "" })
      }
    }
  };

  handleChange = (evt) => {
    this.setState({
      value: evt.target.value,
      error: null,
    });
    this.props.errorUpdate((prevState) => ({ ...prevState, to: undefined }))

  };

  handleDelete = (item) => {
    this.setState({
      items: this.state.items.filter((i) => i !== item),
    });
  };

  handlePaste = (evt) => {
    evt.preventDefault();

    var paste = evt.clipboardData.getData("text");
    var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

    if (emails) {
      var toBeAdded = emails.filter((email) => !this.isInList(email));

      this.setState({
        items: [...this.state.items, ...toBeAdded],
      });
    }
  };

  handleblur = (evt) => {
    evt.preventDefault();
      let value = this.state.value.trim();
      if (value && this.isValid(value)) {
        this.props.update([...this.props.items, { email: this.state.value }])
        this.setState({ value: "" })
      }
};

  isValid(email) {
    let error = null;

    if (this.isInList(email)) {
      error = `${email} has already been added.`;
    }

    if (!this.isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }

    if (error) {
      // this.setState({ error });
      this.props.errorUpdate((prevState) => ({ ...prevState, to: error }))


      return false;
    }

    return true;
  }

  isInList(email) {
    return this.state.items.includes(email);
  }

  isEmail(email) {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  }

  render() {
    return (
      <>
        <div className="chip-wrapper">
          {this.props.items && this.props.items.map((item) => (
            <div className="tag-item" key={item}>
              {item.email}
              <button
                type="button"
                className="button"
                onClick={() => {
                  const filtered = this.props.items.filter(function (o) {
                    if (o.email && o.email != item.email) {
                      return true
                    }
                  });
                  this.props.update(filtered)
                }}
              >
                &times;
              </button>
            </div>
          ))}

          <div className="tag-wrapper">
            <input
              className={"input " + (this.state.error && " has-error")}
              value={this.state.value}
              onKeyDown={this.handleKeyDown}
              onChange={this.handleChange}
              onPaste={this.handlePaste}
              onBlur={this.handleblur}
            />
            {this.state.error && <p className="error">{this.state.error}</p>}
          </div>
        </div>
      </>
    );
  }
}

export default Tags;
