const historyReducer = (state = {}, action) => {
    switch (action.type) {

        case "HISTORY/LIST/SET":
            return { ...state, historyListData: action.payload }

        case "history/pdfExport/SET":
            return { ...state, historyPdfExportData: action.payload }

        case "admin/detailsHistory/SET":
            return { ...state, detailsHistory: action.payload }

        default: return state

    }
}

export default historyReducer;
