
const DashboadReducer = (state = {}, action) => {
    switch (action.type) {

        case "DASHBOAD/CARDS/SET":
            return { ...state, CARDS: action.payload }

        case "DASHBOAD/TRANSFER/HOSTORY/SET":
            return { ...state, transferHistory: action.payload }

        default: return state

    }
}

export default DashboadReducer;