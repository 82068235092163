import React from "react";
import Button from "../../components/Button/Button";
import { subscriptionData, pdfExport } from './action'
import Heading from "../../components/Heading/Heading";
import "./Subscription.scss";
import Export from "./export";
import ExportPDF from "../../assets/export-pdf.png";
import { FaCheckCircle } from "react-icons/fa";
import SubscriptionTable from "./SubscriptionTable";
import { connect } from 'react-redux';

class Subscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      List: {},
      MasterChecked: false,
      SelectedList: [],
      searchBox: ""
    };
  }

  // Select/ UnSelect Table rows
  onMasterCheck(e) {
    let tempList = this.state.List;
    // Check/ UnCheck All Items
    tempList.map((user) => (user.selected = e.target.checked));

    //Update State
    this.setState({
      MasterChecked: e.target.checked,
      List: tempList,
      SelectedList: this.state.List.filter((e) => e.selected),
    });
  }

  // Update List Item's state and Master Checkbox State
  onItemCheck(e, item) {
    let tempList = this.state.List;
    tempList.map((user) => {
      if (user.id === item.id) {
        user.selected = e.target.checked;
      }
      return user;
    });

    //To Control Master Checkbox State
    const totalItems = this.state.List.length;
    const totalCheckedItems = tempList.filter((e) => e.selected).length;

    // Update State
    this.setState({
      MasterChecked: totalItems === totalCheckedItems,
      List: tempList,
      SelectedList: this.state.List.filter((e) => e.selected),
    });
  }

  // Event to get selected rows(Optional)
  getSelectedRows() {
    this.setState({
      SelectedList: this.state.List.filter((e) => e.selected),
    });
  }

  handlePDFToast() {
    var element = document.getElementById("pdftoast");
    element.classList.add("show");
  };

  render() {
    return (
      <>
        <div className="dashboard-wrapper users-page">
          <div>
            <Heading value="Subscription" varient="h2" />
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Dashboard</a>
                </li>
                <li className="breadcrumb-item">
                  <span>Subscription</span>
                </li>
              </ol>
            </nav>
          </div>
          <div>
            <div className="btns-wrapper">
              <div className="d-flex searchbox">
                <input
                  type="text"
                  placeholder="Search user by name, email…"
                  styleClass="search"
                  value={this.state.searchBox}
                  onChange={(event) => {
                    this.setState({ searchBox: event.target.value }
                    )
                    this.props.subscriptionData({ searchStatus: true, search: event.target.value, page : 1 })
                  }}

                />
                <svg
                  id="search-2-line"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.19"
                  height="17.19"
                  viewBox="0 0 17.19 17.19"
                >
                  <path
                    id="Path_15"
                    data-name="Path 15"
                    d="M0,0H17.19V17.19H0Z"
                    fill="none"
                  />
                  <path
                    id="Path_16"
                    data-name="Path 16"
                    d="M8.446,2A6.446,6.446,0,1,1,2,8.446,6.449,6.449,0,0,1,8.446,2Zm0,11.46A5.014,5.014,0,1,0,3.433,8.446,5.013,5.013,0,0,0,8.446,13.46Zm6.077.051,2.026,2.026L15.537,16.55l-2.026-2.026,1.013-1.013Z"
                    transform="translate(-0.567 -0.567)"
                    fill="#858dac"
                  />
                </svg>
              </div>
              <div className="share-btn">
                <button
                  className="btn icon-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#exportPDF"
                  onClick={() => { this.props.pdfExport({ pdfExport: true }) }}
                >
                  Export to PDF
                </button>


                <svg
                  id="share-forward-line"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                >
                  <path
                    id="Path_37"
                    data-name="Path 37"
                    d="M0,0H20V20H0Z"
                    fill="none"
                  />
                  <path
                    id="Path_38"
                    data-name="Path 38"
                    d="M11.333,12.083H9.667a7.5,7.5,0,0,0-6.64,4.008Q3,15.755,3,15.417a8.333,8.333,0,0,1,8.333-8.333V2.5l8.75,7.083-8.75,7.083ZM9.667,10.417H13v2.757l4.434-3.59L13,5.993V8.75H11.333a6.652,6.652,0,0,0-5.048,2.311,9.157,9.157,0,0,1,3.381-.644Z"
                    transform="translate(-0.5 -0.417)"
                    fill="#fff"
                  />
                </svg>
              </div>
              <div className="filter">
                <svg
                  id="filter-2-line"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    id="Path_35"
                    data-name="Path 35"
                    d="M0,0H24V24H0Z"
                    fill="none"
                  />
                  <path
                    id="Path_36"
                    data-name="Path 36"
                    d="M14,14v6l-4,2V14L4,5V3H20V5ZM6.4,5,12,13.394,17.6,5Z"
                    fill="#0f3059"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="exportPDF" tabIndex="-1">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="modal-head">
                  <img src={ExportPDF} alt="ExportPDF" className="export-img" />
                </div>
                <div className="content">
                  <p className="mb-2 alertText">Alert</p>
                  <p>Export data to PDF file</p>
                  <div className="btns">
                    <button
                      type="button"
                      className="btn transparent-btn"
                      data-bs-dismiss="modal"
                    >
                      No
                    </button>
                    {/* <button
                      type="button"
                      className="btn transparent-btn1"
                      data-bs-dismiss="modal"
                      onClick={() => this.handlePDFToast()}
                    >
                      Yes
                    </button> */}
                    <Export />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="toast-position p-3">
          <div
            className="toast"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            id="pdftoast"
          >
            <div className="d-flex">
              <div className="toast-body">
                <FaCheckCircle className="me-2 i" />
                Data successfully imported to PDF
              </div>
              <button
                type="button"
                className="btn-close btn-close-white me-2 m-auto"
                data-bs-dismiss="toast"
                aria-label="Close"
              ></button>
            </div>
          </div>
        </div>
        <div className="subscription-content-wrapper">
          <div className="table-responsive">
            <SubscriptionTable />
          </div>
          {/* <ul className="pagination justify-content-center">
            <li className="page-item">
              <a className="page-link" href="#">
                Previous
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                1
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                2
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                3
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                Next
              </a>
            </li>
          </ul> */}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  console.log("state", state)
  return {
  }
}
const mapDispatchToProps = dispatch => {

  return ({
    subscriptionData: (value) => { dispatch(subscriptionData(value)); },
    pdfExport: (value) => { dispatch(pdfExport(value)); }
  })
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscription);