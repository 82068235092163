
import React from "react";
import "./Login.scss";
import Logo from "../../assets/logo.png";
import ResetPassword from "./forgot-screen/ResetPassword";

function Reset() {
  return (
    <div className="login-wapper d-flex">
      <div className="left-part d-flex flex-row align-content-between flex-wrap">
          <div className="logo-elm">
             <img src={Logo}/>
          </div>
          <div className="left-pt-btom d-flex ">
              <h2>
                <span>Welcome To </span>
                Snapbit
              </h2>
          </div>
      </div>
      <div className="right-part d-flex align-items-center justify-content-center">
        <ResetPassword/>
      </div>
    </div>
  );
}

export default Reset;