import React, { useEffect, useState } from "react";
import "./Email.scss";
import Heading from "../../components/Heading/Heading";
import User from "../../assets/smith.png";
import Pin from "../../assets/pin.png";
import TextEditor from "../../components/TextEditor/TextEditor";
import Tags from "../../components/Tags/Tags";
import CCTags from "../../components/Tags/CCTag";
import { FiTrash, FiX } from "react-icons/fi";
import { connect } from 'react-redux';
import { getUsersList, filteredData, sendMail } from '../Users/action'
import { toast } from 'react-toastify';
const docImage = "docimage.jpg"

function Email(props) {
  let file;
  const [filePath, setFilepath] = useState("");
  const [search, updateSearch] = useState([]);
  const [searchSelected, updateSearchSelected] = useState([]);
  const [BccSelected, updateBccSelected] = useState([]);
  const [CCSelected, updateCCSelected] = useState([]);
  const [submitData, updateSubmitData] = useState({});
  const [error, updateError] = useState({});
  const [Attachment, updateAttachment] = useState([]);

  let { userEmailList, mailStatus } = props

  useEffect(() => {
    props.getUsersList()
    const dropArea = document.querySelector(".drag-area"),
      dragText = dropArea.querySelector(".header-txt"),
      button = dropArea.querySelector(".upload-btn"),
      input = dropArea.querySelector(".fileInput");
    button.onclick = () => {
      input.click();
    };

    input.addEventListener("change", function () {
      file = this.files[0];
      dropArea.classList.add("active");
      console.log("change", file)
      showFile();
    });

    dropArea.addEventListener("dragover", (event) => {
      event.preventDefault();
      dropArea.classList.add("active");
      dragText.textContent = "Release to Upload File";
    });

    dropArea.addEventListener("dragleave", () => {
      dropArea.classList.remove("active");
      dragText.textContent = "Drag & Drop to Upload File";
    });

    dropArea.addEventListener("drop", (event) => {
      event.preventDefault();
      file = event.dataTransfer.files[0];
      updateAttachment((prevState) => {
        console.log(prevState, file, prevState.includes(file))
        if (!prevState.includes(file)) {
          return ([...prevState, file])
        } else {
          return ([...prevState])
        }
      })
      showFile(file);
    });
  }, []);

  useEffect(() => {
    updateSearch(userEmailList)
  }, [userEmailList])

  useEffect(() => {
    updateSearchSelected([])
    updateSubmitData({ subject: "" })
    updateError({})
    updateBccSelected([])
    updateCCSelected([])
    setFilepath('')
    updateAttachment([])
  }, [mailStatus])

  const removePreviewImage = (index) => {
    let val = Attachment.filter((data, id) => {
      return id != index
    })
    updateAttachment(val)

  }

  function showFile(file) {
    return false
    let fileType = file.type;
    let validExtensions = ["image/jpeg", "image/jpg", "image/png"];
    if (validExtensions.includes(fileType)) {
      let fileReader = new FileReader();
      fileReader.onload = () => {
        let fileURL = fileReader.result;
        setFilepath(fileURL);
        let imgTag = `<img src="${URL.createObjectURL(file)}" alt="image" />`;
        dropArea.innerHTML = imgTag;
      };
      fileReader.readAsDataURL(file);
    } else {
      alert("This is not an Image File!");
      dropArea.classList.remove("active");
      dragText.textContent = "Drag & Drop to Upload File";
    }
  }
  updateAttachment
  onsubmit = () => {
    if (!submitData.subject) {
      updateError((prevState) => ({ ...prevState, subject: "Enter Email Subject." }))
    } else {
      updateError((prevState) => ({ ...prevState, subject: undefined }))
    }

    if (!searchSelected[0]) {
      updateError((prevState) => ({ ...prevState, to: "Enter Email Id." }))
    } else {
      updateError((prevState) => ({ ...prevState, to: undefined }))
    }

    if (!submitData.subject || !searchSelected[0]) {
      return false

    }

    let userEmail = searchSelected.map((elem) => {
      return elem.email
    })
    let CCEmail = CCSelected.map((elem) => {
      return elem.email
    })
    let BCCEmail = BccSelected.map((elem) => {
      return elem.email
    })

    props.sendMail({ ...submitData, userEmail, CCEmail, BCCEmail, Attachment });
  }

  let addAtachment = (event) => {

    for (let i = 0; i < event.target.files.length; i++) {
      let fileType = event.target.files[i].type;
      let validExtensions = ["image/jpeg", "image/jpg", "image/png"];
      if (event.target.files[i].size < 1024 * 1024 * 5) {
        updateAttachment((prevState) => ([...prevState, event.target.files[i]]))
        showFile(event.target.files[i]);
      } else {
        toast.error("Size should not be greater than 5MB")
        dropArea.classList.remove("active");
        dragText.textContent = "Drag & Drop to Upload File";
      }
    }

  }
  // console.log("final", Attachment)

  return (
    <>
      <div className="dashboard-wrapper users-page email-page">
        <div>
          <Heading value="Email" varient="h2" />
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard">Dashboard</a>
              </li>
              <li className="breadcrumb-item">
                <span>Email</span>
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="email-page-content">
        <div className="row">
          <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 mb-sm-2">
            <div className="contact-list-wrapper">
              <div className="header">
                <h3>Contacts List</h3>
                <input type="checkbox" onClick={(e) => {
                  if (e.target.checked) {
                    updateSearchSelected(userEmailList)
                  } else {
                    updateSearchSelected([])
                  }
                }} />
              </div>
              <div className="contact-list">
                <form className="d-flex searchbox">
                  <input
                    type="text"
                    placeholder="Search in contact…"
                    styleClass="search"
                    onChange={(event) => {
                      const filtered = userEmailList.filter(function (o) {
                        if (o.email && o.email.toLowerCase().indexOf(event.target.value) > -1) {
                          return true
                        }
                      });
                      updateSearch(filtered)
                    }}

                  />
                  <svg
                    id="search-2-line"
                    xmlns="http://www.w3.org/2000/svg"
                    width="17.19"
                    height="17.19"
                    viewBox="0 0 17.19 17.19"
                  >
                    <path
                      id="Path_15"
                      data-name="Path 15"
                      d="M0,0H17.19V17.19H0Z"
                      fill="none"
                    />
                    <path
                      id="Path_16"
                      data-name="Path 16"
                      d="M8.446,2A6.446,6.446,0,1,1,2,8.446,6.449,6.449,0,0,1,8.446,2Zm0,11.46A5.014,5.014,0,1,0,3.433,8.446,5.013,5.013,0,0,0,8.446,13.46Zm6.077.051,2.026,2.026L15.537,16.55l-2.026-2.026,1.013-1.013Z"
                      transform="translate(-0.567 -0.567)"
                      fill="#858dac"
                    />
                  </svg>
                </form>
                <div className="contacts">
                  {search && search.map((elem, index) => (
                    <div key={index} className="d-flex contact">
                      <div className="d-flex contact-img">
                        <figure>
                          <img src={elem?.avatar ? `${process.env.REACT_APP_API_URL}/avatar/${elem.avatar}` : User} alt="user" />
                        </figure>
                        <h4>{elem?.first_name ? elem.first_name : elem.email}</h4>
                      </div>
                      <input type="checkbox" className="emailCheckbox" checked={searchSelected.some(item => item.email === elem.email)} onChange={(e) => {
                        // const filtered = search.filter(function (o) {
                        //   if (o.email && o.email != elem.email) {
                        //     return true
                        //   }
                        // });
                        // updateSearch(filtered)
                        if (e.target.checked) {
                          updateSearchSelected([...searchSelected, elem])
                          updateError((prevState) => ({ ...prevState, to: undefined }))
                        } else {
                          const filtered = searchSelected.filter(function (o) {
                            if (o.email && o.email != elem.email) {
                              return true
                            }
                          });
                          updateSearchSelected(filtered)
                        }
                      }} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-8 col-lg-7 col-md-6 col-sm-12 mb-sm-2">
            <div className="compose-mail-wrapper">
              <div className="header">
                <h3>Compose Mail</h3>
                <FiTrash className="icon" onClick={() => {
                  updateSearchSelected([])
                  updateSubmitData({ subject: "" })
                  updateError({})
                  updateBccSelected([])
                  updateCCSelected([])
                  updateAttachment([])
                }} />
              </div>
              <div className="form-wrapper">
                <form>
                  <div>
                    <label>To:</label>
                    {/* <input type="email" name="email" /> */}
                    <Tags items={searchSelected} update={updateSearchSelected} errorUpdate={updateError} />
                  </div>
                  {error?.to && <p className="error">{error?.to}</p>}

                  <div>
                    <label>Cc:</label>
                    {/* <input type="email" name="cc" /> */}
                    <CCTags update={updateCCSelected} data={CCSelected} errorUpdate={updateError} />
                  </div>
                  {error?.CC && <p className="error">{error?.CC}</p>}

                  <div>
                    <label>Bcc:</label>
                    {/* <input type="email" name="bcc" /> */}
                    <CCTags update={updateBccSelected} data={BccSelected} errorUpdate={updateError} CCtype="BCC" />
                  </div>
                  {error?.BCC && <p className="error">{error?.BCC}</p>}
                  <div>
                    <label>Subject:</label>
                    <input type="text" value={submitData.subject} onChange={(e) => {
                      updateSubmitData({ ...submitData, subject: e.target.value })
                      updateError({ ...error, subject: undefined })
                    }}
                      name="subject" />
                  </div>
                  {error?.subject && <p className="error">{error?.subject}</p>}
                  <span>
                    <TextEditor value="hello" update={updateSubmitData} data={submitData} />
                  </span>
                  <div className="image-section">
                    {Attachment && Attachment.map((file, index) => {
                      // console.log("file", file.type)
                      let validExtensions = ["image/jpeg", "image/jpg", "image/png"];

                      return (
                        <div className="preview-images">
                          <div>
                            <figure className="position-relative">
                              {validExtensions.includes(file.type) ?
                                <img src={URL.createObjectURL(file)} alt="preview-img" />
                                :
                                <img src={docImage} alt="preview-img" />
                              }
                              <FiX className="close-icon" onClick={() => removePreviewImage(index)} />
                              {file.name.length > 5 ?
                                (
                                  <p>
                                    {`${file.name.substring(0, 5)}...`}
                                  </p>
                                ) :
                                <p>{file.name}</p>
                              }
                            </figure>
                          </div>
                        </div>
                      )
                    })
                    }
                  </div>
                  <div className="file-upload">
                    <div>
                      <div className="upload-btn-wrapper">
                        <figure>
                          <img src={Pin} alt="pin" />
                        </figure>
                        <a
                          type="button"
                          className="uploadbtn"
                          data-bs-toggle="modal"
                          data-bs-target="#uploadFile"
                        >
                          Attach file
                        </a>
                      </div>
                    </div>
                    <a onClick={() => {
                      onsubmit()
                    }}
                      className="btn icon-btn send">Send
                    </a>
                  </div>
                </form>
                <div className="modal fade" id="uploadFile" tabIndex="-1">
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-body">
                        <div className="modal-head">
                          <h4>Attachment</h4>
                        </div>
                        <div className="upload-content">
                          <div className="drag-area">
                            <div className="icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="129.628"
                                height="79.056"
                                viewBox="0 0 129.628 79.056"
                              >
                                <g
                                  id="freepik--Cloud--inject-46"
                                  transform="translate(0.505 0.537)"
                                >
                                  <path
                                    id="Path_261"
                                    data-name="Path 261"
                                    d="M254.4,109.744a24.06,24.06,0,0,0-37.026-24.437A32.463,32.463,0,0,0,154.884,97.66c0,.4,0,.8,0,1.195a21.133,21.133,0,0,0,2.888,41.256v.224H253.36a15.337,15.337,0,0,0,1.035-30.584Z"
                                    transform="translate(-139.543 -62.316)"
                                    fill="#263238"
                                    stroke="#263238"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1"
                                  />
                                  <path
                                    id="Path_262"
                                    data-name="Path 262"
                                    d="M254.4,105.244a24.06,24.06,0,0,0-37.026-24.437,32.463,32.463,0,0,0-62.485,12.346v1.195a21.133,21.133,0,0,0,2.888,41.256v.224H253.36a15.337,15.337,0,0,0,1.035-30.584Z"
                                    transform="translate(-139.543 -60.692)"
                                    fill="#ec3040"
                                    stroke="#263238"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1"
                                  />
                                </g>
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="69.447"
                                height="118.928"
                                viewBox="0 0 69.447 118.928"
                              >
                                <g
                                  id="freepik--Character--inject-46"
                                  transform="translate(0.5 0.5)"
                                >
                                  <path
                                    id="Path_265"
                                    data-name="Path 265"
                                    d="M265.9,261.96l-2.4,2.173,5.176,6.128,3.087-1.189Z"
                                    transform="translate(-211.067 -167.415)"
                                    fill="#263238"
                                    stroke="#263238"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1"
                                  />
                                  <path
                                    id="Path_297"
                                    data-name="Path 297"
                                    d="M244.518,137.344,227.686,116.16,210.86,137.344h6.582v31.894h20.494V137.344Z"
                                    transform="translate(-192.066 -114.786)"
                                    fill="#263238"
                                    stroke="#263238"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1"
                                  />
                                  <path
                                    id="Path_298"
                                    data-name="Path 298"
                                    d="M244.518,135.194,227.686,114.01,210.86,135.194h6.582v31.894h20.494V135.194Z"
                                    transform="translate(-192.066 -114.01)"
                                    fill="#fff"
                                    stroke="#263238"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1"
                                  />
                                  <rect
                                    id="Rectangle_87"
                                    data-name="Rectangle 87"
                                    width="68.447"
                                    height="68.447"
                                    transform="translate(0 49.481)"
                                    fill="#fff"
                                    stroke="#263238"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1"
                                  />
                                  <rect
                                    id="Rectangle_88"
                                    data-name="Rectangle 88"
                                    width="62.082"
                                    height="62.082"
                                    transform="translate(3.182 52.663)"
                                    fill="#263238"
                                    stroke="#263238"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1"
                                  />
                                  <path
                                    id="Path_299"
                                    data-name="Path 299"
                                    d="M234.115,228.467,219.634,243.6l-11.273-3.157a3.834,3.834,0,0,0-3.278.588L186.43,254.585v15.088h62.082V237.72l-8.883-9.26a3.834,3.834,0,0,0-5.515.006Z"
                                    transform="translate(-183.248 -154.901)"
                                    fill="#fff"
                                    stroke="#263238"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1"
                                  />
                                  <circle
                                    id="Ellipse_22"
                                    data-name="Ellipse 22"
                                    cx="7.835"
                                    cy="7.835"
                                    r="7.835"
                                    transform="translate(15.978 73.636) rotate(-67.68)"
                                    fill="#fff"
                                    stroke="#263238"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1"
                                  />
                                </g>
                              </svg>
                            </div>
                            <div className="drag-btn">
                              <header className="header-txt">
                                Drag files here <span>or</span>
                              </header>
                              <button className="btn transparent-btn1 upload-btn">
                                Upload here
                              </button>
                              <input onChange={addAtachment} type="file" hidden className="fileInput" multiple />
                            </div>
                          </div>
                        </div>
                        <div className="btns">
                          <button
                            type="button"
                            className="btn transparent-btn"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                          <button
                            type="button"
                            className="btn icon-btn"
                            data-bs-dismiss="modal"
                          >
                            Ok
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    userEmailList: state.user?.userEmailList?.data,
    mailStatus: state.user?.mailStatus,
  }
}
const mapDispatchToProps = dispatch => {

  return ({
    getUsersList: (value) => { dispatch(getUsersList(value)); },
    filteredData: (value) => { dispatch(filteredData(value)); },
    sendMail: (value) => { dispatch(sendMail(value)); },
  })
};

export default connect(mapStateToProps, mapDispatchToProps)(Email);
