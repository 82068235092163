import axios from 'axios';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

export const ajaxRequest = async (args) => {
    const getuser = Cookies.get('user');
    let jwt = {};
    args.url = `${process.env.REACT_APP_API_URL}/${args.url}`
    if (getuser !== undefined) {
        jwt = `${JSON.parse(getuser).jwt}`
    }

    args.headers = {
        "Content-Type": "application/json",
        jwt,
    }

    if (args.isCustomDataOrImg) {
        const form = new FormData();
        for (const key in args.CustomDataOrImg) {
            if (Array.isArray(args.CustomDataOrImg[key])) {
                for (let j = 0; j < args.CustomDataOrImg[key].length; j++) {
                    if (key == "userEmail") {
                        form.append(`${key}[${j}]`, args.CustomDataOrImg[key][j]);
                    } else {
                        form.append(`${key}`, args.CustomDataOrImg[key][j]);
                    }
                }
            } else {
                // console.log("args.data.business_logo",key ,args.CustomDataOrImg[key]);

                form.append(key, args.CustomDataOrImg[key]);
            }
        }
        args.headers = {
            'Content-Type': 'multipart/form-data; application/json;',
            jwt,
        }
        args.data = form

    }
    return new Promise((resolve, reject) => axios(args).then(response => {
        resolve(response)
    }).catch(error => {
        if (error.response && error.response.data.error === "Invalid token") {
            // toast.error(error.response.data.error)
            Cookies.remove('user')
        }
        reject(error)
    }))
}