export const subscriptionData = (payload) => {
    return {
        type: "LIST",
        payload: payload
    }
}
export const pdfExport = (payload) => {
    return {
        type: "pdf/Export",
        payload: payload
    }
}