import React from "react";
import "./ButtonStyle.scss";

function Button({ value, color, bgcolor, styleName, onClick }) {
  return (
    <>
      <button className={`btn ${styleName}`} onClick={() => onClick()}>
        {value}
      </button>
    </>
  );
}

export default Button;
