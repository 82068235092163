
import React, { useEffect } from "react";
import "../Login.scss";
import { inputField } from "../../../components/Input/fields";
import { isEmail, isEmpty, isBlankSpace } from '../../../Helper/validation'
import { LoginSubmit } from './../action'
import { useNavigate } from "react-router";
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux';



const validate = (values) => {
  const errors = {}

  if (isEmpty(values.email)) {
    errors.email = 'Enter your Email Id.';
  } else if (!isEmail(values.email) || isBlankSpace(values.email)) {
    errors.email = "Please enter valid Email Id."
  }
  if (isEmpty(values.password)) {
    errors.password = 'Enter your Password.';
  }
  return errors

}

function Loginscreen(props) {

  let navigation = useNavigate()
  useEffect(() => {
    if (props?.users?.jwt) {
      navigation("/dashboard")
    }
  }, [props.users])

  let submit = (value) => {
    props.LoginSubmit(value);
  }

  let forgotClick = () => {
    navigation("/forgotPassword");
  }


  return (
    <div className="login-form d-flex flex-column">
      <h3>Login here</h3>
      <form onSubmit={props.handleSubmit((values) => { submit(values) })} className="d-flex flex-column">
        <Field name="email" component={inputField} type="text" styleClass="form-control user-id" />
        <Field name="password" component={inputField} type="password" styleClass="form-control user-id" />

        <div style={{ width: "100%" }}>
          <div style={{ width: "50%", float: "left" }}>
            <div >
              <input className="rememberMe-check" type="checkbox" value="lsRememberMe" id="rememberMe" />
              <label for="rememberMe">Remember me</label>
            </div>
          </div>
          <div style={{ "margin-left": "77%" }}>
            <span onClick={forgotClick} className="d-flex forgot-pass">forget password?</span>
          </div>
        </div>
        <button type="submit" className="login-btn">Sign in</button>
      </form>
    </div>
  );
}

Loginscreen = reduxForm({
  form: '_LoginForm',
  validate,
  destroyOnUnmount: false,
  enableReinitialize: true
})(Loginscreen);

const mapStateToProps = (state, ownProps) => {
  console.log("state", state)
  return {
    users: state.Login.user
  }
}
const mapDispatchToProps = dispatch => {

  return ({
    LoginSubmit: (value) => { dispatch(LoginSubmit(value)); },
  })
};

export default connect(mapStateToProps, mapDispatchToProps)(Loginscreen);
