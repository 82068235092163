import { put, takeEvery, race, call, take } from 'redux-saga/effects'
import { ajaxRequest } from "../../Helper/apiCall"
import { toast } from 'react-toastify';

const delay = (ms) => new Promise(res => setTimeout(res, ms))

export function* DashboardCards(action) {
  yield put({ type: 'LOADING/SET', payload: true })

  try {
    const response = yield race({
      success: call(ajaxRequest, {
        method: 'POST',
        url: `admin/cards`,
      }),
      cancel: take('ROUTE/CHANGE')
    });

    if (response.success) {
      yield put({ type: 'DASHBOAD/CARDS/SET', payload: response.success.data.data })
      yield put({ type: 'LOADING/SET', payload: false })
    }

  } catch (error) {
    action.payload.navigateData('/')
    toast.error(error.response.data.error)
    yield put({ type: 'LOADING/SET', payload: false })
  }

}

export function* DashboardTransferHistory(action) {
  yield put({ type: 'LOADING/SET', payload: true })

  try {
    const response = yield race({
      success: call(ajaxRequest, {
        method: 'POST',
        url: `admin/transferHistory`,
      }),
      cancel: take('ROUTE/CHANGE')
    });

    if (response.success) {
      yield put({ type: 'DASHBOAD/TRANSFER/HOSTORY/SET', payload: response.success.data.data })
      yield put({ type: 'LOADING/SET', payload: false })

    }
  } catch (error) {
    yield put({ type: 'LOADING/SET', payload: false })
  }

}

export function* watchDasboad() {
  yield takeEvery('DASHBOAD/CARDS', DashboardCards)
  yield takeEvery('DASHBOAD/TRANSFER/HOSTORY', DashboardTransferHistory)
}