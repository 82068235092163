export const DashboardCards = (payload) => {
    // console.log(" action.payload", payload)

    return {
        type: "DASHBOAD/CARDS",
        payload: payload
    }
}

export const getTransferHostoryData = (payload) => {
    // console.log(" action.payload", payload)

    return {
        type: "DASHBOAD/TRANSFER/HOSTORY",
        payload: payload
    }
}