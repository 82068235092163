
import React, { useEffect } from "react";
import "../Login.scss";
import { connect } from 'react-redux';
import { submitNewPassword } from './../action'
import { inputField } from "../../../components/Input/fields";
import { Field, reduxForm } from 'redux-form'
import { useNavigate } from "react-router";
import { isEmpty } from '../../../Helper/validation'

const validate = (values) => {
    const errors = {}
  
    if (isEmpty(values.Repeat_Password)) {
      errors.Repeat_Password = 'Enter your confirm password.';
    }
    if (isEmpty(values.Password)) {
      errors.Password = 'Enter your Password.';
    }
    if (values.Password != values.Repeat_Password) {
      errors.Repeat_Password = 'Password confirm password Should be same.';
    }
    return errors
  
  }
function ResetPassword(props) {
    let navigation = useNavigate();

    useEffect(() => {
        (!props.otpCode || props.passwordChangedStatus) && navigation("/")
    })

    let submit = (value) => {
        let obj = {code: String(props.otpCode), email: props.email, password: value.Password}
        console.log("value", value, obj)
        props.submitNewPassword(obj)
    }

    return (
        <div className="login-form d-flex flex-column">
            <h3>Reset password?</h3>
            <form onSubmit={props.handleSubmit((values) => { submit(values) })} className="d-flex flex-column">
                <Field name="Password" component={inputField} type="password" styleClass="form-control user-id" placeholder="Password" />
                <Field name="Repeat_Password" component={inputField} type="password" styleClass="form-control user-id" placeholder="Repeat Password" />
                <button className="login-btn">Submit</button>
            </form>
        </div>
    );
}

ResetPassword = reduxForm({
    form: '_Reset',
    validate,
    destroyOnUnmount: false,
    enableReinitialize: true
})(ResetPassword);

const mapStateToProps = (state, ownProps) => {
    console.log("state>>>>>>>>>>>", state)
    return {
        otpCode: state.Login.otpCode,
        email: state.Login.email,
        passwordChangedStatus: state.Login.passwordChangedStatus
    }
}
const mapDispatchToProps = dispatch => {

    return ({
        submitNewPassword: (value) => { dispatch(submitNewPassword(value)); },
    })
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
