import { put, takeEvery, race, call, take } from 'redux-saga/effects'
import { ajaxRequest } from "../../Helper/apiCall"
import { toast } from 'react-toastify';


export function* LIST(action) {
  yield put({ type: 'LOADING/SET', payload: true })

  try {
    const response = yield race({
      success: call(ajaxRequest, {
        method: 'POST',
        url: `admin/listSubscription`,
        data: action.payload
      }),
      cancel: take('ROUTE/CHANGE')
    });

    if (response.success) {
      yield put({ type: 'SUBSCRIPTION/LIST/SET', payload: response.success.data.data })
      yield put({ type: 'LOADING/SET', payload: false })

    }

  } catch (error) {
    toast.error("Something Went Wrong")
  }

}

export function* pdfExport(action) {
  try {
    const response = yield race({
      success: call(ajaxRequest, {
        method: 'POST',
        url: `admin/listSubscription`,
        data: action.payload
      }),
      cancel: take('ROUTE/CHANGE')
    });

    if (response.success) {
      yield put({ type: 'SUBSCRIPTION/pdfExport/SET', payload: response.success.data.data })
    }

  } catch (error) {
    toast.error("Something Went Wrong")
  }

}


export function* watchSubscription() {
  yield takeEvery('LIST', LIST)
  yield takeEvery('pdf/Export', pdfExport)
}



