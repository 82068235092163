import React from "react";
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'
import { connect } from 'react-redux';
import { historyDetails } from './action'
import Heading from "../../components/Heading/Heading";
import { NavLink } from "react-router-dom";

class History extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1
        }
    }

    float2int(value) {
        return value | 0;
    }

    render() {
        let { hisListData, hirCount } = this.props;
        let page = (hirCount / 10)
        page = page == hirCount ? this.float2int(page) : this.float2int(page + 1)

        let URLID = window.location.search;
        let params = new URLSearchParams(URLID);
        let transfer_id = params.get('transfer_id');

        const columns = [{
            Header: 'S.NO',
            accessor: 'id',
        }, {
            Header: 'File Name',
            accessor: 'file_name',
            // Cell: props => <span className='number'>{props.value}</span> // Custom cell components!
        }, {
            Header: 'File Size',
            accessor: 'file_size',
        }, {
            Header: 'File Type',
            accessor: 'file_type',
        },
            // {
            //     Header: 'From Email',
            //     accessor: 'from_email',
            // },
            // {
            //     Header: 'To Email',
            //     accessor: 'to_email',
            // }
        ]

        return <>
            <div className="dashboard-wrapper users-page">
                <div>
                    <Heading value="Transfer History Details" varient="h2" />
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a href="/dashboard">Dashboard</a>
                            </li>
                            {/* <NavLink to="/transaction-history" >
                                <li className="breadcrumb-item">
                                    <span>Transfer History</span>
                                </li>
                            </NavLink> */}
                            <li className="breadcrumb-item">
                                <span>Transfer History Details</span>
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="subscription-content-wrapper">
                <div className="table-responsive">

                    <ReactTable
                        data={hisListData}
                        columns={columns}
                        pages={page}
                        defaultPageSize={10}
                        pageSizeOptions={[10]}
                        loading={hisListData ? false : true}
                        manual // informs React Table that you'll be handling sorting and pagination server-side
                        onFetchData={(state, instance) => {
                            this.props.historyDetails({ page: state.page + 1, transfer_id })
                            this.setState({
                                page: state.page + 1
                            })
                        }}

                    />
                </div>
            </div>
        </>

    }
}
const mapStateToProps = (state, ownProps) => {
    console.log("state", state)
    return {
        hisListData: state.History?.detailsHistory?.data,
        hirCount: state.History?.detailsHistory?.count,
    }
}
const mapDispatchToProps = dispatch => {

    return ({
        historyDetails: (value) => { dispatch(historyDetails(value)); },

    })
};

export default connect(mapStateToProps, mapDispatchToProps)(History);
