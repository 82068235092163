import React from 'react';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

class Export extends React.Component {

    constructor() {
        super();
        this.state = {
            people: [
                { name: "Keanu Reeves", profession: "Actor" },
                { name: "Lionel Messi", profession: "Football Player" },
                { name: "Cristiano Ronaldo", profession: "Football Player" },
                { name: "Jack Nicklaus", profession: "Golf Player" },
            ]
        }
    }

    exportPDF = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Users";
        const headers = [["S.NO", "Name", "Email", "File", "Data", "Date OF",]];
        const data = this.props.listData.map((elt, index) => [(index+1), elt.name, elt.email, elt.file, elt.data, elt.created]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("SnapBit-Users.pdf")
        toast.success("Data successfully imported to PDF.")

    }

    render() {
        console.log("listData", this.props.listData)
        return (
            <div>
                <button
                    type="button"
                    className="btn transparent-btn1"
                    data-bs-dismiss="modal"
                    onClick={() => this.exportPDF()}
                >
                    Yes
                </button>
                {/* <button onClick={() => this.exportPDF()}>Generate Report</button> */}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        listData: state.user?.usersPdfExportData?.data,
    }
}

export default connect(mapStateToProps)(Export);