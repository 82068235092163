const subReducer = (state = {}, action) => {
    switch (action.type) {

        case "SUBSCRIPTION/LIST/SET":
            return { ...state, listData: action.payload }

        case "SUBSCRIPTION/pdfExport/SET":
            return { ...state, pdfExportData: action.payload }

        default: return state

    }
}

export default subReducer;
