import React, { useEffect } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";
import { Doughnut, Bar } from "react-chartjs-2";
import "./Dashboard.scss";
import Heading from "../../components/Heading/Heading";
// import Card from "../../components/Card/Card";
import { FiArrowUpCircle, FiArrowDownCircle } from "react-icons/fi";
import { connect } from 'react-redux';
import { DashboardCards, getTransferHostoryData } from './action'
import { useNavigate } from "react-router";

function Dashboard(props) {
  const navigate = useNavigate()

  useEffect(() => {
    props.DashboardCards({navigateData : navigate})
    props.getTransferHostoryData()
  }, [])

  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement
  );

  const getData = (canvas) => {
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 650);
    gradient.addColorStop(0, "#3F87DE");
    gradient.addColorStop(0.5, "#3F87DE");
    gradient.addColorStop(0.8, "#2268C0");
    gradient.addColorStop(1, "#2268C0");

    const gradient1 = ctx.createLinearGradient(0, 0, 0, 650);
    gradient1.addColorStop(0, "#EC3040");
    gradient1.addColorStop(0.5, "#EC3040");
    gradient1.addColorStop(0.8, "#F16A75");
    gradient1.addColorStop(1, "#F16A75");

    return {
      labels: ["Active", "Other"],
      datasets: [
        {
          label: "Chart",
          data: [props?.CARDS?.active_users, props?.CARDS?.other_users],
          backgroundColor: [gradient, gradient1],
        },
      ],
    };
  };

  const Options = {
    cutout: "25%",
    responsive: true,
  };

  const canvas = document.createElement("canvas");
  const chartData = getData(canvas);

  const getData1 = (canvas1) => {
    const ctx1 = canvas1.getContext("2d");

    const gradient1 = ctx1.createLinearGradient(0, 0, 0, 380);
    gradient1.addColorStop(0, "#EC3040");
    gradient1.addColorStop(0.5, "#EC3040");
    gradient1.addColorStop(0.8, "#F16A75");
    gradient1.addColorStop(1, "#F16A75");
    let data = [0, 0, 0, 0, 0, 0, 0]
    if (props?.transferHistory?.data) {
      props.transferHistory.data.map((element, index) => {
        data[element.day - 1] = element.tranferdData
      })
    }

    return {
      labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      datasets: [
        {
          label: "Chart",
          data: data,
          backgroundColor: [gradient1],
        },
      ],
    };
  };
  const options = {
    responsive: true,
    barValueSpacing: 20,
  };

  const canvas1 = document.createElement("canvas");
  const chartData1 = getData1(canvas1);

  return (
    <div className="dashboard-wrapper">
      <Heading value="Dashboard" varient="h2" />
      <div className="breadcrumb">
        <a href="">
          Home <span>/</span> <span>Dashboard</span>
        </a>
      </div>
      <div className="cards-wrapper">
        <div className="defaultCard top-card">
          <h5>Active Users</h5>
          <div className="card-content">
            <h2 className="count">{props?.CARDS?.active_users}</h2>
            <div className="card-img">
              <svg
                id="user-line"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  id="Path_3"
                  data-name="Path 3"
                  d="M0,0H24V24H0Z"
                  fill="none"
                />
                <path
                  id="Path_4"
                  data-name="Path 4"
                  d="M4,22a8,8,0,1,1,16,0H18A6,6,0,1,0,6,22Zm8-9a6,6,0,1,1,6-6A6,6,0,0,1,12,13Zm0-2A4,4,0,1,0,8,7,4,4,0,0,0,12,11Z"
                  fill="#fff"
                />
              </svg>
            </div>
          </div>
          <p>
            {parseInt(props?.CARDS?.active_users_persent) < 0 ?
              <FiArrowDownCircle className="first i" />
              :
              <FiArrowUpCircle className="first i" />
            }
            <span>{props?.CARDS?.active_users_persent}%</span> last month
          </p>
        </div>
        <div className="defaultCard top-card">
          <h5>Other Users</h5>
          <div className="card-content">
            <h2 className="count">{props?.CARDS?.other_users}</h2>
            <div className="card-img">
              <svg
                id="user-3-line"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  id="Path_31"
                  data-name="Path 31"
                  d="M0,0H24V24H0Z"
                  fill="none"
                />
                <path
                  id="Path_32"
                  data-name="Path 32"
                  d="M20,22H18V20a3,3,0,0,0-3-3H9a3,3,0,0,0-3,3v2H4V20a5,5,0,0,1,5-5h6a5,5,0,0,1,5,5Zm-8-9a6,6,0,1,1,6-6A6,6,0,0,1,12,13Zm0-2A4,4,0,1,0,8,7,4,4,0,0,0,12,11Z"
                  fill="#fff"
                />
              </svg>
            </div>
          </div>
          <p>
            {parseInt(props?.CARDS?.other_users_persent) < 0 ?
              <FiArrowDownCircle className="second i" />
              :
              <FiArrowUpCircle className="second i" />
            }
            <span>{props?.CARDS?.other_users_persent}%</span> last month
          </p>
        </div>
        <div className="defaultCard top-card">
          <h5>Active Subscription</h5>
          <div className="card-content">
            <h2 className="count">{props?.CARDS?.active_subscription}</h2>
            <div className="card-img">
              <svg
                id="bank-card-2-line"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  id="Path_5"
                  data-name="Path 5"
                  d="M0,0H24V24H0Z"
                  fill="none"
                />
                <path
                  id="Path_6"
                  data-name="Path 6"
                  d="M3,3H21a1,1,0,0,1,1,1V20a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V4A1,1,0,0,1,3,3Zm17,9H4v7H20Zm0-4V5H4V8Z"
                  fill="#fff"
                />
              </svg>
            </div>
          </div>
          <p>
            {parseInt(props?.CARDS?.active_subscription_persentage) < 0 ?
              <FiArrowDownCircle className="fourth i" />
              :
              <FiArrowUpCircle className="fourth i" />
            }
            <span>{props?.CARDS?.active_subscription_persentage}%</span> last month
          </p>
        </div>
        <div className="defaultCard top-card">
          <h5>Other Subscription</h5>
          <div className="card-content">
            <h2 className="count">{props?.CARDS?.other_subscription}</h2>
            <div className="card-img">
              <svg
                id="bank-card-line"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  id="Path_33"
                  data-name="Path 33"
                  d="M0,0H24V24H0Z"
                  fill="none"
                />
                <path
                  id="Path_34"
                  data-name="Path 34"
                  d="M3,3H21a1,1,0,0,1,1,1V20a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V4A1,1,0,0,1,3,3Zm17,8H4v8H20Zm0-2V5H4V9Zm-6,6h4v2H14Z"
                  fill="#fff"
                />
              </svg>
            </div>
          </div>
          <p>
            {parseInt(props?.CARDS?.sub_users_persent) < 0 ?
              <FiArrowDownCircle className="fourth i" />
              :
              <FiArrowUpCircle className="fourth i" />
            }
            <span>{props?.CARDS?.sub_users_persent}%</span> last month
          </p>
        </div>
      </div>
      <div className="card-chart-wrapper">
        <div className="row">
          <div className="col-md-12 col-lg-6 col-xl-5">
            <div className="defaultCard Doughnut-card">
              <div className="card-head">
                <h4>Users</h4>
              </div>
              <div className="card-content">
               
                <Doughnut data={chartData} options={Options} />              
                <div className="aboutChart mt-3">
                  <div>
                    <p>Active Users</p>
                    <h4>{props?.CARDS?.active_users} ({props?.CARDS?.active_users_persent}%)</h4>

                    <p>last 3 months</p>
                  </div>
                  <div>
                    <p className="other">Other Users</p>
                    <h4>{props?.CARDS?.other_users}</h4>

                    <p>last 3 months</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6 col-xl-7">
            <div className="defaultCard Doughnut-card">
              <div className="card-head">
                <h4>Transfer History</h4>
              </div>
              <div className="card-content">
                <p className="vertical-indicator-text">megabytes (MB)</p>
                <Bar data={chartData1} options={options} />
                <div className="py-2">
                  <p className="indicator">Data Transfer each day (in mb)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  console.log("state", state)
  return {
    CARDS: state.Dashboad.CARDS,
    transferHistory: state.Dashboad.transferHistory,
  }
}
const mapDispatchToProps = dispatch => {

  return ({
    DashboardCards: (value) => { dispatch(DashboardCards(value)); },
    getTransferHostoryData: (value) => { dispatch(getTransferHostoryData(value)); }
  })
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
