import _ from 'lodash'
export function isEmpty(value) {
    return !_.trim(value)
}

export function isEmail(value) {
    // eslint-disable-next-line no-useless-escape
    return !!(value && /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(value.trim()))
}

export function isBlankSpace(value) {
    return !!(value && / /.test(value));
}

export function isNumber(value) {
    return !!(value && /^[0-9]*$/i.test(value))
}

export function maxLenght(value, length) {
    return !!(value && value.length > length);
}
