import { all, fork } from "redux-saga/effects";
import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import rootReducer from "./combineReducers"
import { watchDasboad } from "./../pages/Dashboard/saga"
import { watchLogin } from "./../pages/Auth/saga"
import { watchSubscription } from '../pages/Subscriptions/saga'
import { watchHistory } from '../pages/History/saga'
import { watchUsers } from '../pages/Users/saga'


let combineSaga = function* () {
    yield all([
        fork(watchDasboad),
        fork(watchLogin),
        fork(watchSubscription),
        fork(watchHistory),
        fork(watchUsers)
    ]);
}


const sagaMiddleware = createSagaMiddleware()
const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(combineSaga)

export default store;