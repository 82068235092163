export const LoginSubmit = (payload) => {
    return {
        type: "LOGIN",
        payload: payload
    }
}
export const Forgot = (payload) => {
    return {
        type: "FORGOT",
        payload: payload
    }
}
export const getAdminEmailID = (payload) => {
    return {
        type: "get/Admin/EmailID",
        payload: payload
    }
}
export const FrogotSubmit = (payload) => {
    return {
        type: "Frogot/Submit",
        payload: payload
    }
}
export const OtpSubmit = (payload) => {
    return {
        type: "Otp/Submit",
        payload: payload
    }
}
export const submitNewPassword = (payload) => {
    return {
        type: "Submit/New/Password",
        payload: payload
    }
}
export const Logout = (payload) => {

    return {
        type: "LOGOUT",
        payload: payload
    }
}
